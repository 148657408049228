import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { NotifyService } from "./notify.service";
import jwt_decode from "jwt-decode";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
@Injectable({
  providedIn: "root",
})
export class ApiServiceService {
  private refreshSource = new BehaviorSubject<boolean>(false);
  refresh = this.refreshSource.asObservable();
  refreshTransfer(refresh: boolean) {
    this.refreshSource.next(refresh);
  }
  dataSource = new BehaviorSubject<boolean>(false);
  data = this.dataSource.asObservable();

  dataTransfere(data: boolean) {
    this.dataSource.next(data);
  }

   server: string= 'https://mauritransfert.cadorim.com/api/' 
   // server: string= 'https://mauritransfert.alimara.net/api/'
   //server: string= 'https://pre-prod.alimara.net/api/' 	
  
  
  private number: Subject<boolean>;

  public headers = new HttpHeaders({
    "Content-Type": "application/json ; charset=UTF-8",
    "x-access-token": this.getToken(),
  });
  public token = "";
  refToken;
  constructor(
    public http: HttpClient,
    public route: Router,
    public notifyService: NotifyService,
    private modal: NgbModal,
    private translate: TranslateService
  ) {
    this.number = new Subject<boolean>();
  }

  login(body, file) {
    let manager_number_agencies = 0;
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
    });

    let options = {
      headers: headers,
    };
    let resultat;
    this.http
      .post(this.server + "auth/login", JSON.stringify(body), options)
      .subscribe(
        (res: any) => {
          resultat = res;
          if (res.message == "user Not found.") {
            this.notifyService.warningNotify(
              "top",
              "center",
              "Utilisateur n'existe pas!"
            );
            console.log(res.message);
          } else if (res.message == "Invalid Password!") {
            this.notifyService.warningNotify(
              "top",
              "center",
              "Mot de passe invalide!"
            );
            console.log(res.message);
          } else {
            console.log(res);
            //this.sessionExpirationTTL("TTL","expiration",600000);
            localStorage.setItem("transfert_last_updatedAt", null);
            manager_number_agencies = res.manager_number_agencies;
            localStorage.setItem(
              "manager_number_agencies",
              JSON.stringify(manager_number_agencies)
            );
            localStorage.setItem(
              "refreshToken",
              JSON.stringify(res.refreshToken)
            );
            localStorage.setItem("token", JSON.stringify(res.accessToken));
            localStorage.setItem("agence", JSON.stringify(res.agency));
            localStorage.setItem("user", JSON.stringify(res));
            localStorage.setItem(
              "privilleges",
              JSON.stringify(res.privilleges)
            );
            var id = JSON.parse(localStorage.getItem("user")).id;
            this.token = JSON.parse(localStorage.getItem(res.accessToken));
            this.refToken = JSON.parse(localStorage.getItem(res.refreshToken));
            //console.log(res.agency);
            console.log(JSON.parse(localStorage.getItem("refreshToken")));
            /* if(JSON.parse(localStorage.getItem("user")).roles[0] == 'OPERATEUR'){
                //  this.route.navigateByUrl('caisse-operateur');
                }
                if(JSON.parse(localStorage.getItem("user")).roles[0] == 'SUPERMANAGER' ){
                  this.route.navigateByUrl('caisse');

                }
                if(JSON.parse(localStorage.getItem("user")).roles[0] == 'MANAGER' && (res.manager_number_agencies && res.manager_number_agencies == 1 ) ){
                  this.route.navigateByUrl('manager-caisse-lite');
                }
                if(JSON.parse(localStorage.getItem("user")).roles[0] == 'MANAGER' && (res.manager_number_agencies && res.manager_number_agencies > 1 ) ){
                  this.route.navigateByUrl('caisse');
                }
                if(JSON.parse(localStorage.getItem("user")).roles[0] == 'ADMIN' ){
                  this.route.navigateByUrl('caisse-admin');
                }
                if(JSON.parse(localStorage.getItem("user")).roles[0] == 'SUPERVISEUR'){
                  localStorage.setItem("supervisor_manager",JSON.stringify(res.user_parent));
                  if((manager_number_agencies && manager_number_agencies == 1) ){

                    this.route.navigateByUrl('manager-caisse-lite')
                  }
                  else{this.route.navigateByUrl('caisse')}
                }

                if(JSON.parse(localStorage.getItem("user")).roles[0] == 'OPERATEUR'){
                  localStorage.setItem("supervisor_manager",JSON.stringify(res.user_parent));
                  this.route.navigateByUrl('caisse-operateur');
                }

                */
          }
        },
        (err) => {
          console.log(err);
          if (err.status != 0) {
            this.notifyService.errorNotify(
              "top",
              "center",
              this.translate.instant("Message.incorrect_informations")
            );
          } else {
            this.notifyService.servorErrorNotify(err.status, err);
          }
          /*if(err.error.message == 'User Not found.'){
              this.notifyService.errorNotify('top','center',"Utilisateur introuvable avec ce username et mot de passe");
             }else{
               this.notifyService.servorErrorNotify(err.status,err)
             }*/
          // this.notifyService.errorNotify('top','center',"Délai expiré veuillez reessayez!");
        },
        () => {
          //alert("connected: "+JSON.parse(localStorage.getItem("user")).roles[0])
          let manager_of_the_supervisor;
          if (
            JSON.parse(localStorage.getItem("user")).roles[0] == "SOUSADMIN"
          ) {
            //localStorage.setItem("supervisor_manager",JSON.stringify(manager_of_the_supervisor));
            this.route.navigateByUrl("profil");
          }
          if (
            JSON.parse(localStorage.getItem("user")).roles[0] ==
              "SUPERVISEUR" ||
            JSON.parse(localStorage.getItem("user")).roles[0] == "OPERATEUR"
          ) {
          }
          if (
            JSON.parse(localStorage.getItem("user")).roles[0] == "SUPERMANAGER"
          ) {
            //this.route.navigateByUrl('caisse');
            this.route.navigateByUrl("account-details");
          }
          if (
            JSON.parse(localStorage.getItem("user")).roles[0] == "MANAGER" &&
            resultat.manager_number_agencies &&
            resultat.manager_number_agencies == 1
          ) {
            this.route.navigateByUrl("manager-caisse-lite");
          }
          if (
            JSON.parse(localStorage.getItem("user")).roles[0] == "MANAGER" &&
            resultat.manager_number_agencies &&
            resultat.manager_number_agencies > 1
          ) {
            this.route.navigateByUrl("caisse");
          }
          if (JSON.parse(localStorage.getItem("user")).roles[0] == "ADMIN") {
            this.route.navigateByUrl("caisse-admin");
          }
          if (
            JSON.parse(localStorage.getItem("user")).roles[0] == "SUPERVISEUR"
          ) {
            localStorage.setItem(
              "supervisor_manager",
              JSON.stringify(resultat.user_parent)
            );
            if (manager_number_agencies && manager_number_agencies == 1) {
              this.route.navigateByUrl("manager-caisse-lite");
            } else {
              //this.route.navigateByUrl('caisse');
              this.route.navigateByUrl("account-details");
            }
          }

          if (
            JSON.parse(localStorage.getItem("user")).roles[0] == "OPERATEUR"
          ) {
            localStorage.setItem(
              "supervisor_manager",
              JSON.stringify(resultat.user_parent)
            );
            this.route.navigateByUrl("caisse-operateur");
          }

          //this.notifyService.successNotify('top','center',this.translate.instant('Message.connected_success'));
        }
      );
    //59 sec timeout
  }

  authData(body, file) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });

    let options = {
      headers: headers,
    };
    return this.http.post(this.server + file, JSON.stringify(body), options);
    //59 sec timeout
  }
  //Agency Methodes
  addAgeny(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "add/agency",
      JSON.stringify(body),
      options
    );
  }

  getAgencies(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/agencies", options);
  }
  getManagersAndSousPartenairesAgencies(idmanager): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "get/sous_manager/agency/" + idmanager,
      options
    );
  }

  updateAgencie(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "update/agency/" + id,
      JSON.stringify(body),
      options
    );
  }
  deleteAgency(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "enable-desable/agency/" + id, options);
  }
  //Methodes Roles
  getRoles(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/roles", options);
  }

  //Methodes comptes
  addAccount(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "add/account",
      JSON.stringify(body),
      options
    );
  }

  updateAccount(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "update/account/" + id,
      JSON.stringify(body),
      options
    );
  }

  deleteAccount(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "enable-desable/account/" + id, options);
  }

  deletePartener(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "deactive_partener/" + id, options);
  }

  getAccounts(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/accounts", options);
  }

  getAgencyAccounts(number): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/agence/account/" + number, options);
  }
  getAgencyAccountsBydate(number, body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    let tbody = {};
    return this.http.post(
      this.server + "get/agence/date/account/" + number,
      body,
      options
    );
  }
  getAccountDepotCashBydate(number, body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    let tbody = {};
    return this.http.post(
      this.server + "get/cashdeposed/account/" + number,
      body,
      options
    );
  }

  getManagerDepotsCash(idmanager): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    let tbody = {};
    return this.http.get(
      this.server + "get/manager/cashdeposed/accounts/" + idmanager,
      options
    );
  }

  getAgencyAccountsByTwodate(number, body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    let tbody = {};
    return this.http.post(
      this.server + "get/agence/between/date/account/" + number,
      body,
      options
    );
  }
  getAgencyAccountsManager(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "get/manager/agence/account-transaction",
      options
    );
  }
  getManagersAccountsDailyResume(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    let tbody = {};
    return this.http.post(
      this.server + "manager/account-transaction/date/resume",
      body,
      options
    );
  }

  getImaraAccountsOperationsByDate(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    let tbody = {};
    return this.http.post(
      this.server + "get/imara/transactions-journal",
      body,
      options
    );
  }

  getAccountDetailsByDate(id, body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(
      this.server + "get/manager/account/details/" + id,
      body,
      options
    );
  }

  reinitialiseAccounts(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(this.server + "get/reactualise/tables", options);
  }
  // Add charge for a manager
  addAccountCharge(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(this.server + "add/account/charge", body, options);
  }

  updateAccountCharge(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(this.server + "update/charge", body, options);
  }

  delteAccountCharge(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(this.server + "delete/charge", body, options);
  }

  // Get all charges of a manager
  getManagerCharges(body, id_manager): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(
      this.server + "get/charges/manager/" + id_manager,
      body,
      options
    );
  }

  getCaisseCharges(body, number): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(
      this.server + "get/charges/caisse/" + number,
      body,
      options
    );
  }

  getPrivilleges(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(this.server + "get/all/privilleges", options);
  }

  getAllAccounts(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(this.server + "get/all/accounts", options);
  }


  addPrivilegeToUser(id, body) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    
    return this.http.post(
      this.server + "add/privileges/user/" + id,
      body,
      options
    );
  }

  addAccountToUser(id, body) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
   
    return this.http.post(
      this.server + "add/accounts/user/" + id,
      body,
      options
    );
  }

  addClotureAccount(body) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(this.server + "add/account/cloture", body, options);
  }

  getLastClotureAccount(id_account) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(
      this.server + "get/cloture-accounts/" + id_account,
      options
    );
  }

  getListClotureAccount(id_account) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(
      this.server + "get/list/clotures-account/" + id_account,
      options
    );
  }
  //Methodes transfert
  addTransfert(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "add/deposit/transfer",
      JSON.stringify(body),
      options
    );
  }

  // ENVOIE DE CReDIt
  sendCreditTransfert(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "add/send_credit/transfer",
      JSON.stringify(body),
      options
    );
  }
  payementFacture(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "add/paiement_facture/transfer",
      JSON.stringify(body),
      options
    );
  }

  updateFacture(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "facture/update/transfer/" + id,
      JSON.stringify(body),
      options
    );
  }

  updateTransfert(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "update/transfer/" + id,
      JSON.stringify(body),
      options
    );
  }

  updateSuspendedOrientation(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "update/suspended-orientation/transfer/" + id,
      JSON.stringify(body),
      options
    );
  }

  orientSuspendedTransfert(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "orientation/transfer-suspended/" + id,
      JSON.stringify(body),
      options
    );
  }

  deleteTransfert(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "delete/transfer/" + id,
      JSON.stringify(body),
      options
    );
  }
  getTransferts(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(this.server + "get/transfers", body, options);
  }
  getTransfertById(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/transfer/" + id, options);
  }

  getTransfertManager(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "get/manager/all/transfer",
      body,
      options
    );
  }

  populateNejma(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "populate/nejma/transfert", options);
  }

  getTransfertManagerSearchByExpedOrBenefNum(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "get/search/transfer/by-benef-or-exped",
      body,
      options
    );
  }

  getManagerAgenceCaisseInfo(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "get/manager/account-caisses/info",
      body,
      options
    );
  }

  getImaraWaitingAmountInCaisse(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/imara/waiting-amount", options);
  }

  getManagerWaitingAmountInCaisse(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "get/manager/waiting-amount/" + id,
      options
    );
  }

  getTransfertInComming(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "get/manager/incoming/transfer",
      options
    );
  }
  //  Get Transfert entrants  between to date
  getTransfertInCommingBetweenDate(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "get/manager/incoming/transfer",
      body,
      options
    );
  }

  getTransfertInCommingLivredBetweenDate(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "get/manager/incoming-livred/transfer",
      body,
      options
    );
  }

  getTransfertOutComming(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "get/manager/outcoming/transfer",
      options
    );
  }

  // Transfert out comming between
  getTransfertOutCommingBetweenDate(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "get/manager/outcoming/transfer",
      body,
      options
    );
  }

  getTransfertTransitBetweenDate(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "get/manager/transfert-transit",
      body,
      options
    );
  }

  getTransferDetails(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/details/transfer/" + id, options);
  }

  getSearchTransfertByClause(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "get/search/waiting/transfer",
      body,
      options
    );
  }
  //Methodes type-Transfert
  getTransfertType(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/list/transfer/types", options);
  }

  annuleRetraitTransfert(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "annuler/picktransfer/transaction/" + id,
      JSON.stringify(body),
      options
    );
  }
  annuleRetraitTransfertSuspended(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "reversepick/transfer-suspended/" + id,
      JSON.stringify(body),
      options
    );
  }

  annuleTransfertSuspended(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "delete/transfer/suspended/" + id,
      JSON.stringify(body),
      options
    );
  }

  confirmDepot(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "add/cash/depot/transfer",
      JSON.stringify(body),
      options
    );
  }

  pickTransfertSuspended(body: any, id_agence_to): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "pick/suspended/transfer/" + id_agence_to,
      JSON.stringify(body),
      options
    );
  }

  //Methodes users
  addUser(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "add/user",
      JSON.stringify(body),
      options
    );
  }
  getUsers(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/users", options);
  }

  getAllUsers(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/all/users/list", options);
  }
  getUsersForAdmin(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/all/users", options);
  }

  getUserById(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/detail/user/" + id, options);
  }
  getUserLogById(id,body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(this.server + "get/detail/user/log/" + id, body, options);
  }
  getAgencyLogById(id, body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(this.server + "get/detail/agency/log/" + id, body, options);
  }
  getTransactionLog(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/transaction/log/" + id, options);
  }
  getTransferLogById(id, body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(this.server + "get/detail/transfer/log/" + id, body, options);
  }

  getCurrentUserDetail(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/detail/current/user", options);
  }
  getManagersLevelOne(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/managers/level-one", options);
  }

  deleteUser(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    let body: {};
    return this.http.get(this.server + "enable-deable/user/" + id, options);
  }

  updateUser(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "update/user/" + id,
      JSON.stringify(body),
      options
    );
  }

  updateUserPartenaire(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "update-partenaire/user/" + id,
      JSON.stringify(body),
      options
    );
  }

  updateCurentUser(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "update/current/user",
      JSON.stringify(body),
      options
    );
  }

  // Listes of users [operateur and superviser ] for a manager
  getManagerUsers(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/manager/users/" + id, options);
  }
  getManagerPartenairesUsers(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "get/manager-partenaire-operators/" + id,
      options
    );
  }

  getUserSupervisorDetails(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/supervisor/detail", options);
  }

  getManagersTo(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/list/manager", options);
  }
  changeUserRole(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(this.server + "user/change-roles", body, options);
  }

  getAccords(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/agreements", options);
  }
  updateAccord(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "update/agreement/" + id,
      JSON.stringify(body),
      options
    );
  }

  deleteAccord(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(
      "http://api-dev.cadorim.com/api/enable-desable/agreement/" + body,
      options
    );
  }

  addAccord(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "add/agreement",
      JSON.stringify(body),
      options
    );
  }

  // Method logout
  public doLogout() {
    localStorage.removeItem("user");
    localStorage.removeItem("agence");
    localStorage.removeItem("privilleges");
    localStorage.removeItem("supervisor_manager");
    localStorage.removeItem("journal_startDate");
    localStorage.removeItem("journal_endDate");
    localStorage.removeItem("liste_transferts");
    localStorage.removeItem("transfert_last_updatedAt");
    this.dataTransfere(false);
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    let body = {};
    return this.http.post(
      this.server + "auth/logout",
      JSON.stringify(body),
      options
    );
  }

  public doTransfertCredit(tel, msg, operateur_telephonique) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      /* 'x-access-token':  this.getToken()*/
    });
    let options = {
      //headers : headers
    };
    let body = {};
    return this.http.get(
      "http://192.168.1.102/?tel=" +
        tel +
        "&msg=" +
        msg +
        "&action=" +
        operateur_telephonique +
        "",
      options
    );
  }
  tokenIncre = 0;
  //

  getToken() {
    if (localStorage.getItem("token")) {
      let oldtoken = JSON.parse(localStorage.getItem("token"));
      let refreshedToken = this.getRefreshToken();
      //let token = this.getToken();
      let body;
      let user = JSON.parse(localStorage.getItem("user"));
      if (user)
        body = {
          refreshToken: refreshedToken,
          //token: oldtoken,
          id: user.id,
          level: user.level,
          id_parent: user.id_parent,
        };
      let headers = new HttpHeaders({
        "Content-Type": "application/json ; charset=UTF-8",
        "x-access-token": oldtoken,
      });
      let options = { headers: headers };
      this.http
        .post(this.server + "auth/token", JSON.stringify(body), options)
        .subscribe(
          (res: any) => {
            console.log("newToken :", res.token);
            localStorage.setItem("token", JSON.stringify(res.token));
            //refreshedToken = JSON.parse(localStorage.getItem("refreshToken"));
          },
          (err) => {
            if (err.error.message == "jwt expired") {
              this.logOutFromInterceptor();
              oldtoken = "";
              /* this.notifyService.errorNotify(
              "top",
              "center",
              "Délai expiré veuillez reessayez!"
              //this.translate.instant("Message.incorrect_informations")
            ); */
            } else {
              this.notifyService.servorErrorNotify(err.status, err);
            }
          }
        );

      //console.log("OldToken",oldtoken)

      return oldtoken;
    } else {
      return null;
    }
  }

  getRefreshToken() {
    return JSON.parse(localStorage.getItem("refreshToken"));
  }

  // Is authenticated method

  public get isAuthenticated(): boolean {
    if (localStorage.getItem("token")) {
      return true;
    }
    return false;
  }

  // Is authenticated method

  public get isManager(): boolean {
    let roles = [];
    if (localStorage.getItem("token")) {
      roles = JSON.parse(localStorage.getItem("user")).roles.filter(
        (item) => item == "MANAGER" || item == "SUPERMANAGER"
      );
      if (roles.length > 0) {
        return true;
      }
    }
    return false;
  }

  public get isAdmin(): boolean {
    if (localStorage.getItem("token")) {
      if (JSON.parse(localStorage.getItem("user")).roles[0] == "ADMIN") {
        return true;
      }
    }
    return false;
  }

  // Methodes des Transactions
  public getTransactions(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/transactions", options);
  }

  // listes des accords applique sur une agence
  public getAccordByAgence(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "get/agreements/by/id/agency/" + id,
      options
    );
  }

  // le total de transfert entre deux agences
  public getAllTransfertTwoAgence(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/total/transfers", options);
  }

  // Affecter Agence-User
  AssociateUserAgence(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "associate-desassociate/user/agency",
      JSON.stringify(body),
      options
    );
  }

  public getPartenerAgencies(id_partener): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "get/partener/agencies/" + id_partener,
      options
    );
  }

  // update etat du zone d'une agence

  updateagenceZoneEtat(id_agence, body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "update/etat-zone/agency/" + id_agence,
      JSON.stringify(body),
      options
    );
  }

  // get distributions cases

  retraitTransfert(body: any, id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "picktransfer/transaction/" + id,
      JSON.stringify(body),
      options
    );
  }
  getReceivedTransfer(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(this.server + "get/incoming/transfer/" + id, options);
  }

  getTransferByAgence(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(this.server + "get/all/transfer/" + id, options);
  }
  getSendTransfer(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(this.server + "get/outgoing/transfer/" + id, options);
  }

  // change password
  public changePassword(id, body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "update/password/" + id,
      JSON.stringify(body),
      options
    );
  }

  public updateAnotherUserPassword(id, body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "reset-user/password/" + id,
      JSON.stringify(body),
      options
    );
  }

  public getParentName(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "showname/parent/user",
      JSON.stringify(body),
      options
    );
  }
  public getAgenceManagerChilds(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "get/agency/manager-childs/" + id,
      options
    );
  }
  public getManagerPartenaires(id): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(this.server + "get/manager/partnaires/" + id, options);
  }

  allAgencies(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(this.server + "get/all/agencies", options);
  }

  getDistributionsRoutes(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(this.server + "get/all/distribution/routes", options);
  }

  updateDistributionsRoutes(id, body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(
      this.server + "update/route/distribution/" + id,
      body,
      options
    );
  }

  getCurrentTransfertPickerDistribution(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(
      this.server + "get/transfert-pick/distribution",
      body,
      options
    );
  }

  getTransfertSuspendedDetails(code_suspension): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(
      this.server + "get/details/transfer-suspended/" + code_suspension,
      options
    );
  }

  createPrivilleges(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(this.server + "create/privillege", body, options);
  }

  /*sessionExpirationTTL(key,value,ttl){
    const now = new Date();
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item));
  }
  */
  /*getExpirationSession(key){
    console.log("Salut Mrs "+key)
    const now = new Date();
    if(localStorage.getItem(key)){
      var ttl = JSON.parse(localStorage.getItem(key));
      console.log(now.getTime());
      console.log(ttl.expiry);
      if(now.getTime() >= ttl.expiry){
        this.doLogout();
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('privilleges');
        this.notifyService.successNotify('top','center',"Votre session est expire veuillez reconnecter");
        this.route.navigate(['/dash']);
      } else{
        console.log('Session still a live');
      }
    }else{
      console.log('Session expired'+ ttl.expiry);
    }
  }*/
  count = 0;
  refreshToken(body): Observable<any> {
    let refreshedToken = this.getRefreshToken();
    let token;
    body = { refreshToken: refreshedToken };
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
    });
    let options = { headers: headers };
    this.http
      .post(this.server + "auth/token", JSON.stringify(body), options)
      .subscribe((res: any) => {
        this.count++;
        console.log(res);
        console.log("token refreshed successfuly");
        localStorage.setItem("refreshToken", JSON.stringify(res.refreshToken));
        localStorage.setItem("token", JSON.stringify(res.accessToken));
        refreshedToken = JSON.parse(localStorage.getItem("refreshToken"));
        token = JSON.parse(localStorage.getItem("token"));
      });
    return token;
  }

  saveTokens(tok, refreshTok) {
    localStorage.setItem("refreshToken", JSON.stringify(refreshTok));
    localStorage.setItem("token", JSON.stringify(tok));
  }

  getAllCity() {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.get(this.server + "villes/all", options);
  }

  addCity(body) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(this.server + "add/ville", body, options);
  }

  updateCity(body) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(this.server + "update/ville", body, options);
  }

  searchTransacionSuspendedTransfertsByNumber(body) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(
      this.server + "get/transaction-suspended-by-number",
      body,
      options
    );
  }

  logOutFromInterceptor() {
    this.doLogout();
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    localStorage.removeItem("agence");
    localStorage.removeItem("privilleges");
    localStorage.removeItem("supervisor_manager");
    this.modal.dismissAll("close");

    this.route.navigate(["/dash"]);
  }

  // Gestion de l'augmentation des capitaux
  increaseCapitalAccount(body: any): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "manager/increase_capital",
      JSON.stringify(body),
      options
    );
  }

  increaseCapitalCaisseAccount(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "manager/caisse_account/increase_capital",
      JSON.stringify(body),
      options
    );
  }

  increaseCapitalMoutamad(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "increase/moutamd_solde",
      JSON.stringify(body),
      options
    );
  }
  updateIncreaseCapitalMoutamad(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "update/increase/moutamd_solde",
      JSON.stringify(body),
      options
    );
  }

  deleteIncreaseCapitalMoutamad(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "delete/increase/moutamd_solde",
      JSON.stringify(body),
      options
    );
  }
  deleteIncreaseCapitalWithCaisse(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "manager/delete/capital_operation_with_caisse",
      JSON.stringify(body),
      options
    );
  }

  updateIncreaseCapitalWithCaisse(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "manager/update/capital_operation_with_caisse",
      JSON.stringify(body),
      options
    );
  }

  bimBankVersement(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "partener/recharge/account/add",
      body,
      options
    );
  }

  // bim bank list of demande de retraits
  bimBankClientListeRetraits(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "partener/bim-client/list/retraits",
      body,
      options
    );
  }

  BimBankValidationRetraitForClient(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "partener/bim-client/valid/retrait",
      body,
      options
    );
  }

  bimGetOtherBankList(): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "partener/get/bank-list",
      options
    );
  }

  bimDepotWithOtherBank(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "partener/recharge/otherbank/add",
      body,
      options
    );
  }

  bimSaveDemandRetraitWithOtherBank(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "partner/otherbank/add/retrait-demand",
      body,
      options
    );
  }

  
  

  getAllTransfertJournalByUpdatedAt(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "get/journal/all/transfer/by-updatedAt",
      body,
      options
    );
  }

  getBimBankAccountByPhone(phone) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "partener/get/account/by/phone/" + phone,
      options
    );
  }

  getCapitalManagrOperations(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(
      this.server + "get/manager/capital/operations",
      body,
      options
    );
  }

  getExcel(body: any) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };

    return this.http.post(this.server + "get/nejma/export-excel", body, {
      headers: headers,
      responseType: "blob",
      //swithCredentials:true
    });
  }

  verifDateIntervalleIsValid(start, end) {
    let debut = new Date(start);
    let fin = new Date(end);

    if (debut <= fin) {
      console.log("date intervalle is ok ");
      return true;
    }
    console.log("date intervalle is not ok ");
    this.notifyService.infoNotify(
      "top",
      "center",
      this.translate.instant("Message.invalid_date_interval")
    );
    return false;
  }

  inputControlPhone(input: any, type: string) {
    console.log(input.target.value);

    if (input.target.value.length >= 8) {
      input.target.value = input.target.value.slice(0, 8);
    }
    if (type == "sender") {
      return input.target.value;
    }
    if (type == "benif") {
      return input.target.value;
    }
    return;
  }

  getResumeAllTransfert(body) {



    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "get/resume-all-transfert",body,
      options
    );
  }


  mauripayVersement(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "mauripay/recharge/account/add",
      body,
      options
    );
  }

  //list retraits cadorim
  cadorimListeRetraits(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "cadorim/get/retrait",
      body,
      options
    );
  }
  //list retraits cadorim by phone
  // cadorimListeRetraitsByPhone(body): Observable<any> {
  //   let headers = new HttpHeaders({
  //     "Content-Type": "application/json ; charset=UTF-8",
  //     "x-access-token": this.getToken(),
  //   });
  //   let options = {
  //     headers: headers,
  //   };
  //   return this.http.post(
  //     this.server + "cadorim/get/retraits/by/phone",
  //     body,
  //     options
  //   );
  // }
  //list retraits mauripay
  mauripayClientListeRetraits(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "mauripay/client/list/retraits",
      body,
      options
    );
  }

  mauripayValidationRetraitForClient(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });

    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "mauripay/client/valid/retrait",
      body,
      options
    );
  }
  cadorimValidateRetrait(body): Observable<any> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });

    let options = {
      headers: headers,
    };
    return this.http.post(
      this.server + "cadorim/validate/retrait",
      body,
      options
    );
  }

  getMauripayAccountByPhone(phone) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json ; charset=UTF-8",
      "x-access-token": this.getToken(),
    });
    let options = {
      headers: headers,
    };
    return this.http.get(
      this.server + "mauripay/get/account/by/phone/" + phone,
      options
    );
  }
}
