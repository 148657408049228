import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Location} from '@angular/common';
import { ApiServiceService } from 'app/Api/api-service.service';
import { NotifyService } from 'app/Api/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { PrivillegeServiceService } from 'app/Api/privillege-service.service';
import { CaisseOperateurComponent } from 'app/layouts/admin-layout/pages/caisse-operateur/caisse-operateur.component';
import { type } from 'os';
import { TransfertService } from 'app/Api/transfert.service';
import { OtherBankComponent } from './other-bank/other-bank.component';


export interface Manager {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export interface Admin {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export interface Operateur {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export interface Super {
  path: string;
  title: string;
  icon: string;
  class: string;
}

@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit{

    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    lng:string;
    srch:any;
    ag_from;
    roles:any =[]
    user:any;
    agence:any = {}
    agences:any = []
    agencesFrom:any =[]
    typeTrans:any = []
    ismanager:boolean = false;
    issupervisor:boolean = false
    isoperateur:boolean = false;
    isadmin:boolean = false;
    public isCollapsed = true;
    initAgences:any[]=[]
    initlistes:any
    listes:any;
    operations:any = [];
    privilleges:any =[];
    have_to_create_transfert:boolean = false;
    have_to_update_transfert:boolean = false;
    have_to_delete_transfert:boolean = false;
    have_to_pick_transfert:boolean = false;
    have_to_reverse_pick_transfert:boolean = false;
    //isMontantLimite : boolean = false;
  //  dropdownSettings:IDropdownSettings = {};
    selectedItems = [];
    agences_to_libere :any = [];
    InConnectedZone:boolean = false;
    managerId:number = 0;
    current_manager_agencies:any=[]
    initialAgences:any[] =[];
    agenceOrigin;
    agence_credit_id:number = 0;
    agence_credit = {};
    curent_facture_type:String=''
    manager_number_agencies;
    bankOperationOrigine="bimbank";
    rolesInformation={operateur:false,manager:false,admin:false,supervisor:false,}

    MANAGER: Manager[] = [
      { path: '/dashboard',     title: this.translate.instant('Sidebar.acceuil'),         icon:'nc-bank',       class: '' },
      { path: '/profil',         title: this.translate.instant('Sidebar.profil'),             icon:'nc-circle-10',    class: '' },
      { path: '/caisse',    title: this.translate.instant('Sidebar.caisse'),        icon:'nc-money-coins', class: '' },
      { path: '/account',    title: this.translate.instant('Dashboard.rapport'),        icon:'nc-chart-bar-32', class: '' },
  ];
  SUPER: Super[] = [
      { path: '/dashboard',     title:  this.translate.instant('Sidebar.acceuil'),         icon:'nc-bank',       class: '' },
      { path: '/profil',         title: this.translate.instant('Sidebar.profil'),             icon:'nc-circle-10',    class: '' },
      { path: '/accord',      title: this.translate.instant('Sidebar.accord'),            icon:'nc-briefcase-24',    class: '' },
      { path: '/caisse',    title: this.translate.instant('Sidebar.caisse'),        icon:'nc-money-coins', class: '' },
      { path: '/distributions',    title:  this.translate.instant('Sidebar.distribution'),        icon:'nc-refresh-69', class: '' },

  ];
  ADMIN: Admin[] = [
      { path: '/dashboard',     title:  this.translate.instant('Sidebar.acceuil'),         icon:'nc-bank',       class: '' },
      { path: '/profil',         title: this.translate.instant('Sidebar.profil'),             icon:'nc-circle-10',    class: '' },
      { path: '/role',          title: this.translate.instant('Sidebar.role'),             icon:'nc-tag-content',      class: '' },
      { path: '/accord',      title: this.translate.instant('Sidebar.accord'),            icon:'nc-briefcase-24',    class: '' },
      { path: '/type-transfert',      title: this.translate.instant('Transfert.type'),              icon:'nc-send',  class: '' },
      { path: '/rapport',         title: this.translate.instant('Dashboard.rapport'),       icon:'nc-pin-3',    class: '' },

  ];
  OPERATEUR: Operateur[] = [
      { path: '/caisse' ,title: this.translate.instant('Sidebar.caisse'),       icon:'nc-refresh-69', class: ''},
      { path: '/profil', title:this.translate.instant('Sidebar.profil'),            icon:'nc-circle-10',    class: '' },
  ];
    @ViewChild("navbar-cmp", {static: false}) button;
    fermer:string;
    transferts:any=[]
  manager_info: any;
  transfert_gain: any;
  distributions: any;
  credit_message_retour: any;
  userLevel: any;
  agences_facture: any=[];
  active_facture_buton: boolean=true;
  is_sous_admin: boolean=false;
  current_transfer: any;
  depot_type: string = 'depot';
  kyc:boolean=false;
  agences_normal: any=[];
  bim_valid_phone: boolean=false;
  mauripay_valid_phone: boolean=false;
  cadorim_valid_phone: boolean=false;
  cadorim_valid_commande: boolean=false;
  bim_bank_current_account:any={last_name:'',first_name:'',email:'',adresse:''};
  mauripay_current_account:any={last_name:'',first_name:''};
  managerAgencyChild: any=[];
  have_to_confirm_depot: boolean;
  bank_operation_type: String ='';
  mauripay_operation_type: String ='';
  demandes_listes : any[] = [];
  have_to_add_operation_bank: boolean=false;
  have_to_add_operation_mauripay: boolean=false;
  have_to_add_operation_cadorim: boolean=false;
  agences_origines: any[]=[];
  deposant_agences: any[]=[];
  current_demande_retrait: any={};
  depot_confirm_active: boolean=true;
    constructor(location:Location,
       private renderer : Renderer2, private element : ElementRef,
       private router: Router,
       private apiService : ApiServiceService,
        private transfertService:TransfertService,
       public translate : TranslateService,
       public privillegeServices:PrivillegeServiceService,
       public notifyService : NotifyService,private fb:FormBuilder,private modal: NgbModal) {
         this.lng = localStorage.getItem('lng')
         this.translate.use(localStorage.getItem("lng"))

         this.userLoged();
         //this.getTypes();
         this.privilleges = JSON.parse(localStorage.getItem("privilleges"))
         this.repartitionPrivilleges()

         this.AllAgencies();
         if(this.ismanager || this.issupervisor){
          this.getAgenceManagerChilds()
         }
         if(!this.isoperateur){
           this.getAgencies()
         }
         this.prepareMultiselectList()
         console.log(this.lng);
         this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;

    }
    dropdownSettings:IDropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      enableCheckAll:true,

      noDataAvailablePlaceholderText:'No Data',


    };
    searchtransfertControls=this.fb.group({
      clause:['',Validators.required],
    })
    transfertControlls = this.fb.group({
      amount:['',],//g-pattern="/^[0-9]{8}$/"
      fee:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      phone_sender:['',[Validators.required,Validators.pattern('^-?[0-9]{8}\\d*(\\.\\d{1,2})?$'),Validators.maxLength(8),Validators.minLength(8)]],
      phone_beneficaire:['',[Validators.required,Validators.pattern('^-?[0-9]{8}\\d*(\\.\\d{1,2})?$'),Validators.maxLength(8),Validators.minLength(8)]],
      first_name_sender:['',],
      last_name_sender:['',],
      first_name_beneficiare:['',],
      last_name_beneficiare:['',],
      selected_agence_to:['',Validators.required],
      selected_agence_from:['',],
      id_agence_from:  ['',],
      id_agence_to:['',],
      type_transfert:[''],
      nni_sender:['',[Validators.minLength(6),Validators.maxLength(22),Validators.pattern(/^[.\d]+$/)]],
      birth_date_sender:[''],
      birth_city_sender:[''],
      nni_beneficiare:['',[Validators.minLength(6),Validators.maxLength(22),Validators.pattern(/^[.\d]+$/)]],
      birth_city_beneficiare:[''],
      birth_date_beneficiare:[''],
      comment:['']

    });
    depoControlls = this.fb.group({
      id:[''],
      fee:['',],
      selected_agence_from:['',],
      id_agence_from:['',],
      selected_agence_to:['',Validators.required],
      id_agence_to:['',],
      type_transfert:[''],
      comment:[''],
      amount:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      phone_beneficaire:['',[Validators.pattern(/^[.\d]+$/),Validators.maxLength(8),Validators.minLength(8)]],
      phone_sender:['',[Validators.pattern(/^[.\d]+$/),Validators.maxLength(8),Validators.minLength(8)]],
    })
    capitalControls = this.fb.group({
      selected_agence_from:['',[Validators.required]],
      id_agence_from:['',],
      type:['',Validators.required],
      comment:[''],
      amount:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      number_account:[''],
      id_manager:['']

    })

    creditControlls = this.fb.group({
      amount:['',Validators.required],
      phone_beneficiare:['',[Validators.maxLength(8),Validators.minLength(8),Validators.pattern(/^[.\d]+$/)]],
      id_agence_from:  [''],
      id_agence_to:[''],
      selected_agence_from:['']
    })
    banKControls = this.fb.group({
      montant:['',],
      phone_beneficaire:['',[Validators.required,Validators.pattern('^-?[0-9]{8}\\d*(\\.\\d{1,2})?$'),Validators.maxLength(8),Validators.minLength(8)]],
      id_agence_from:  [''],
      selected_agence_from:[''],
      id_agence_to:  [''],
      note:[''],
      nom_agence:[''],
      code_agence:[''],
      first_name:[''],
      last_name:[''],
      code:[''],
      id_retrait:[''],
      password:['']

    })

    cadorimControls = this.fb.group({
      montant:['',],
      fee:['',],
      idcommande:['',[Validators.required,Validators.pattern('^-?[0-9]{8,15}\\d*(\\.\\d{1,2})?$'),Validators.maxLength(15),Validators.minLength(8)]],
      phone_beneficaire:['',[Validators.required,Validators.pattern('^-?[0-9]{8}\\d*(\\.\\d{1,2})?$'),Validators.maxLength(8),Validators.minLength(8)]],
      id_agence_from:  [''],
      selected_agence_from:[''],
      id_agence_to:  [''],
      note:[''],
      nom_agence:[''],
      code_agence:[''],
      last_name_benef:[''],
      nni_benef:[''],
      phone_benef:[''],
      first_name:[''],
      last_name:[''],
      code:[''],
      id_retrait:[''],
      password:['']

    })
    mauripayControls = this.fb.group({
      montant:['',],
      phone_beneficaire:['',[Validators.required,Validators.pattern('^-?[0-9]{8}\\d*(\\.\\d{1,2})?$'),Validators.maxLength(8),Validators.minLength(8)]],
      id_agence_from:  [''],
      selected_agence_from:[''],
      id_agence_to:  [''],
      note:[''],
      nom_agence:[''],
      code_agence:[''],
      last_name_benef:[''],
      nni_benef:[''],
      type:[''],
      first_name:[''],
      last_name:[''],
      code:[''],
      id_retrait:[''],
      password:['']

    })
    veriBankAccountControls=this.fb.group({
      first_name:['hello'],
      last_name:['yyy']
    })
    factureControlls = this.fb.group({
      id:[''],
      amount:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      phone_sender:['',[Validators.maxLength(8),Validators.minLength(8),Validators.pattern(/^[.\d]+$/)]],
      id_agence_from:  [''],
      id_agence_to:[''],
      reference_facture:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      fee:['',[Validators.pattern(/^[.\d]+$/)]],
      type:['',Validators.required],
      type_transfert:[''],


      selected_agence_from:[''],
      selected_agence_to:['']
    })
    pickFactureControls = this.fb.group({
      id:[''],
      amount:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      phone_sender:['',[Validators.maxLength(8),Validators.minLength(8),Validators.pattern(/^[.\d]+$/)]],
      id_agence_from:  [''],
      id_agence_to:[''],
      reference_facture:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      fee:[''],
      type:['',Validators.required],
      type_transfert:[''],
      gain:[''],


      selected_agence_from:[''],
      selected_agence_to:['']
    })

    addFactureControlls = this.fb.group({
      id:[''],
      amount:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      phone_sender:['',[Validators.required,Validators.pattern('^-?[0-9]{8}\\d*(\\.\\d{1,2})?$'),Validators.maxLength(8),Validators.minLength(8)]],
      id_agence_from:  [''],
      id_agence_to:[''],
      reference_facture:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      fee:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      type:['',Validators.required],
      //type_transfert:[''],


      selected_agence_from:[''],
      selected_agence_to:['']
    })
    libereControlls = this.fb.group({
      id:[''],
      comment:[''],
      document:[''],
      amount:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      phone_sender:['',Validators.required],
      first_name_sender:['',],
      last_name_sender:['',],
      phone_beneficaire:['',Validators.required],
      first_name_beneficiare:['',],
      last_name_beneficiare:['',],
      fee:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      id_agence_from:  [''],
      id_agence_to:[''],
      selected_agence_to:['',],
      type_transfert:['',Validators.required],
      nni_sender:['',[Validators.minLength(6),Validators.maxLength(22),Validators.pattern(/^[.\d]+$/)]],
      birth_date_sender:[''],
      birth_city_sender:[''],
      nni_beneficiare:['',[Validators.minLength(6),Validators.maxLength(22),Validators.pattern(/^[.\d]+$/)]],
      birth_city_beneficiare:[''],
      birth_date_beneficiare:[''],
      gain:['']

    })

    updateControlls = this.fb.group({
      id:[''],
      amount:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      phone_sender:['',[Validators.maxLength(8),Validators.minLength(8),Validators.pattern(/^[.\d]+$/)]],
      first_name_sender:['',],
      last_name_sender:['',],
      phone_beneficaire:['',[Validators.maxLength(8),Validators.minLength(8),Validators.pattern(/^[.\d]+$/)]],
      first_name_beneficiare:['',],
      last_name_beneficiare:['',],
      fee:['',[Validators.required,Validators.pattern(/^[.\d]+$/)]],
      id_agence_from:  [''],
      selected_agence_from:['',Validators.required],
      selected_agence_to:['',Validators.required],
      id_agence_to:['',],
      type_transfert:['',Validators.required],
      nni_sender:['',[Validators.minLength(6),Validators.maxLength(22),Validators.pattern(/^[.\d]+$/)]],
      birth_date_sender:[''],
      birth_city_sender:[''],
      nni_beneficiare:['',[Validators.minLength(6),Validators.maxLength(22),Validators.pattern(/^[.\d]+$/)]],
      birth_city_beneficiare:[''],
      birth_date_beneficiare:[''],
      comment:['']

    });
    annuleTransfertForm = this.fb.group({
      comment:['',Validators.required],

    });

    deleteControlls = this.fb.group({
      id:[''],
    });
    bankPasswordControls= this.fb.group({
      password:['',[Validators.required]],
    });
    mauripayPasswordControls= this.fb.group({
      password:['',[Validators.required]],
      last_name_beneficiare:['',],
      nni_beneficiare:['',[Validators.minLength(6),Validators.maxLength(22),Validators.pattern(/^[.\d]+$/)]],
    });
    cadorimPasswordControls= this.fb.group({
      password:['',[Validators.required]],
      last_name_beneficiare:['',],
      nni_beneficiare:['',[Validators.minLength(6),Validators.maxLength(22),Validators.pattern(/^[.\d]+$/)]],
    });
    ngOnInit(){
       // this.apiService.getExpirationSession("TTL");
        this.listTitles = this.MANAGER.filter(listTitle => listTitle);
        this.listTitles = this.ADMIN.filter(listTitle => listTitle);
        this.listTitles = this.OPERATEUR.filter(listTitle => listTitle);
        this.listTitles = this.SUPER.filter(listTitle => listTitle);
       // this.prepareMultiselectList()
        this.repartitionPrivilleges()
        var navbar : HTMLElement = this.element.nativeElement;

        this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
        console.log('++++++++++++++++++++++++++++++++++++++++++')
        console.log(this.toggleButton)
        console.log('++++++++++++++++++++++++++++++++++++++++++')
        this.router.events.subscribe((event) => {
          this.sidebarClose();
       });
    }

    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }
    sidebarToggle() {
      if (this.sidebarVisible === false) {
          this.sidebarOpen();

      } else {
          this.sidebarClose();
      }
    }
    sidebarOpen() {var mainPanel: HTMLElement;
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        if(this.lng=='ar'){
          mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel1')[0];
       }else{
          mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
       }
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);

        html.classList.add('nav-open');
        if (window.innerWidth < 991) {
          mainPanel.style.position = 'fixed';
        }
        this.sidebarVisible = true;
    };
    sidebarClose() {var mainPanel: HTMLElement;
        const html = document.getElementsByTagName('html')[0];
        if(this.lng=='ar'){
           mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel1')[0];
        }else{
           mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
        }
        //const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel1')[0];
        if (window.innerWidth < 991) {
          setTimeout(function(){
            mainPanel.style.position = '';
          }, 500);
        }
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };
    collapse(){
      this.isCollapsed = !this.isCollapsed;
      const navbar = document.getElementsByTagName('nav')[0];
      console.log(navbar);
      if (!this.isCollapsed) {
        navbar.classList.remove('navbar-transparent');
        navbar.classList.add('bg-white');
      }else{
        navbar.classList.add('navbar-transparent');
        navbar.classList.remove('bg-white');
      }

    }
    public logout(){
        console.log("Logout")
        this.apiService.doLogout().subscribe(
          res=>{
            console.log(res)
          },
          error=>{
            console.log(error)
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
            localStorage.removeItem('agence');
            localStorage.removeItem('privilleges')
            localStorage.removeItem("supervisor_manager")

            this.router.navigate(['/dash']);
          },
          ()=>{
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
            localStorage.removeItem('agence');
            localStorage.removeItem('privilleges')
            localStorage.removeItem("supervisor_manager")
            this.router.navigate(['/dash']);
            //this.notifyService.successNotify('top','center',this.translate.instant('Message.logout_success'));

          }
        )
    }

    langue1(langue :string){
        console.log(langue)
        this.translate.use(langue);
        if(langue =='ar'){
          localStorage.setItem('lng','fr');
          window.location.reload();
          document.documentElement.dir ="rtl";
          //window.location.reload();
        }else{
         localStorage.setItem('lng','ar');
         window.location.reload();
         document.documentElement.dir ="ltr";
         window.location.reload();
        }
    }
    CaisseOperateur
    userLoged(){
      this.user =JSON.parse(localStorage.getItem("user"));
      this.roles =JSON.parse(localStorage.getItem("user")).roles;
      console.log(this.roles[0])
      console.log(this.roles)
      this.isAdmin(); this.isManager(); this.isOperateur(); this.isSupervisor();this.isSousAdmin()
      if(this.ismanager || this.issupervisor){
        this.userLevel = this.manager_info.level
      }
    }

    isOperateur(){
      let  roles:[]= this.roles.filter(item => item == "OPERATEUR" );
      if(roles.length >0){
        this.manager_info = JSON.parse(localStorage.getItem("supervisor_manager"));
      if(localStorage.getItem("agence") !== "undefined"){
        this.isoperateur=true
        this.rolesInformation.operateur=true
        this.agence = JSON.parse(localStorage.getItem("agence"))
        this.ag_from = this.agence.id;

      }
    }
    }

    isManager(){
      let  roles:[]= this.roles.filter(item => item == "MANAGER" || item == "SUPERMANAGER"  );
      console.log(roles)
      if(roles.length >0){
      this.ismanager = true;
      this.managerId = this.user.id
      this.manager_info = this.user
      this.rolesInformation.manager=true

      this.manager_number_agencies = localStorage.getItem("manager_number_agencies")
      if(this.manager_number_agencies){this.manager_number_agencies = parseInt(this.manager_number_agencies)
      }else{
        this.manager_number_agencies = 0
      }
    }
    }

    isSupervisor(){
      let  roles:[]= this.roles.filter(item => item ==  "SUPERVISEUR"  );
      console.log(roles)
      if(roles.length >0){
      this.issupervisor = true;
      this.rolesInformation.supervisor=true
      this.managerId = this.user.id_parent
      this.manager_info = JSON.parse(localStorage.getItem("supervisor_manager"));
    }
    this.manager_number_agencies = localStorage.getItem("manager_number_agencies")
    if(this.manager_number_agencies){this.manager_number_agencies = parseInt(this.manager_number_agencies)
    }else{
      this.manager_number_agencies = 0
    }
    }

    isAdmin(){
      let  roles:[]= this.roles.filter(item => item == "ADMIN" );
      if(roles.length >0){
        this.isadmin = true;
        this.manager_info = this.user
        this.rolesInformation.admin=true
    }
    }
    isSousAdmin(){
      let  roles:[]= this.roles.filter(item => item == "SOUSADMIN" );
      if(roles.length >0){
        this.is_sous_admin = true;
        this.manager_info = this.user
        this.rolesInformation.admin=true
      }
    }


     open(x,transfert){

      if(transfert == 'addtransfert'){
        this.transfertControlls.reset()
        if(!this.isoperateur && !this.isadmin && !this.is_sous_admin){
          if(this.current_manager_agencies.length == 1){
            this.transfertControlls.patchValue({
              id_agence_from: this.current_manager_agencies[0].id,
              selected_agence_from:this.current_manager_agencies[0].name+'('+this.current_manager_agencies[0].code_agence+')'
            })
          }
        }
        this.transfertControlls.get("fee").setValue(0);

      }
       console.log(transfert)
       this.modal.dismissAll('modal')
       this.libereControlls.get('id').setValue(transfert.id);
       this.libereControlls.get('amount').setValue(transfert.amount);

       this.deleteControlls.get('id').setValue(transfert)
      this.updateControlls.get("id").setValue(transfert.id)
  this.updateControlls.get("amount").setValue(transfert.amount)
  this.updateControlls.get("phone_sender").setValue(transfert.phone_sender)
  this.updateControlls.get("first_name_sender").setValue(transfert.first_name_sender)
  this.updateControlls.get("comment").setValue(transfert.comment)
  this.updateControlls.get("last_name_sender").setValue(transfert.last_name_sender)
  this.updateControlls.get("phone_beneficaire").setValue(transfert.phone_beneficaire)
  this.updateControlls.get("first_name_beneficiare").setValue(transfert.first_name_beneficiare)
  this.updateControlls.get("last_name_beneficiare").setValue(transfert.last_name_beneficiare)
  this.updateControlls.get("fee").setValue(transfert.fee)
  this.updateControlls.get("id_agence_from").setValue(transfert.id_agence_from)
  this.updateControlls.get("id_agence_to").setValue(transfert.id_agence_to)
  this.updateControlls.get("type_transfert").setValue(transfert.type_transfert)

  this.updateControlls.get("nni_sender").setValue(transfert.nni_sender)
  this.updateControlls.get("nni_beneficiare").setValue(transfert.nni_beneficiare)
  this.updateControlls.get("birth_city_sender").setValue(transfert.birth_city_sender)
  this.updateControlls.get("birth_city_beneficiare").setValue(transfert.birth_city_beneficiare)
  this.updateControlls.get("birth_date_sender").setValue(transfert.birth_date_sender)
  this.updateControlls.get("birth_date_beneficiare").setValue(transfert.birth_date_beneficiare)
  this.current_transfer = transfert

  if(transfert.id_agence_to){
    let agenceTo= this.initialAgences.filter(item=>item.id == transfert.id_agence_to )
  console.log("agence to get coode aaagence name ",agenceTo, "transfert.id_agence_to :",transfert.id_agence_to)

  this.updateControlls.get("selected_agence_to").setValue(agenceTo[0].name+'('+this.searchAgenceByCode(this.initialAgences,transfert.id_agence_to)+')')
  }
  if(transfert.id_agence_from){
    let agencefrom= this.initAgences.filter(item=>item.id == transfert.id_agence_from )
  console.log("agence to get coode aaagence name ",agencefrom, "transfert.id_agence_from :",transfert.id_agence_from)

  this.updateControlls.get("selected_agence_from").setValue(agencefrom[0].name+'('+this.searchAgenceByCode(this.agences,transfert.id_agence_from)+')')
  }


     // console.log(transfert);{size: 'lg', windowClass: 'modal-xl'}
      this.modal.open(x,{ariaLabelledBy : 'modal-basic-title', size: 'xl',backdrop:'static',keyboard:false})
      .result.then( (result:any)=>{
          this.fermer = `closed with :${result}`
      },(reason)=>{
        this.fermer = `Dismissed ${this.dism(reason)}`;
      }
      );
    }

    openLiberationModal(x,transfert){
      this.libereControlls.reset()
      console.log("les agences From ",this.current_manager_agencies)

     if(!this.isoperateur){
      if(this.manager_info.level == 2){

        this.agences_to_libere = this.agencesFrom.filter(item => (item.id !== transfert.id_agence_from ))
        console.log("Hello im manager libere ",this.agences_to_libere)
      }
      else{
        if(this.isadmin || this.is_sous_admin){
          this.agences_to_libere = this.agences.filter(item => (item.id !== transfert.id_agence_from ))
        }else{
        this.agences_to_libere = this.current_manager_agencies.filter(item => (item.id !== transfert.id_agence_from ))
        }
      }

     }else{
       console.log("Initial Agences",this.initialAgences)
       let agenceCode= this.searchAgenceByCode(this.initialAgences,this.agence.id)
       console.log("Le selected agence est ",agenceCode)
       this.libereControlls.get('selected_agence_to').setValue(agenceCode)
       console.log("AGENCE SELECTED AGENCE",agenceCode)
      this.agences_to_libere = this.agencesFrom.filter(item => (item.id !== transfert.id_agence_from ))
     }
      //console.log('TEST'+transfert)
      this.current_transfer = transfert
     this.modal.dismissAll('modal')
     this.libereControlls.get('id').setValue(transfert.id);
     this.libereControlls.get('amount').setValue(transfert.amount);
     this.libereControlls.get('id').setValue(transfert)
    this.libereControlls.get("id").setValue(transfert.id)
this.libereControlls.get("amount").setValue(transfert.amount)
this.libereControlls.get("phone_sender").setValue(transfert.phone_sender)
this.libereControlls.get("first_name_sender").setValue(transfert.first_name_sender)
this.libereControlls.get("comment").setValue(transfert.comment)
this.libereControlls.get("last_name_sender").setValue(transfert.last_name_sender)
this.libereControlls.get("phone_beneficaire").setValue(transfert.phone_beneficaire)
this.libereControlls.get("first_name_beneficiare").setValue(transfert.first_name_beneficiare)
this.libereControlls.get("last_name_beneficiare").setValue(transfert.last_name_beneficiare)
this.libereControlls.get("fee").setValue(transfert.fee)
this.libereControlls.get("id_agence_from").setValue(transfert.id_agence_from)
this.agenceOrigin=transfert.id_agence_from;
this.libereControlls.get("id_agence_to").setValue(transfert.id_agence_to)
this.libereControlls.get("type_transfert").setValue(transfert.type_transfert)

this.libereControlls.get("nni_sender").setValue(transfert.nni_sender)
this.libereControlls.get("nni_beneficiare").setValue(transfert.nni_beneficiare)
this.libereControlls.get("birth_city_sender").setValue(transfert.birth_city_sender)
this.libereControlls.get("birth_city_beneficiare").setValue(transfert.birth_city_beneficiare)
this.libereControlls.get("birth_date_sender").setValue(transfert.birth_date_sender)
this.libereControlls.get("birth_date_beneficiare").setValue(transfert.birth_date_beneficiare)
//let str= ''this.searchAgenceByCode(this.agences_to_libere,transfert.id_agence_to)
this.current_transfer.agence_from_name = this.getAgenceNameByManagerPosition(transfert.id_agence_from)
this.current_transfer.agence_to_name = this.getAgenceNameByManagerPosition(transfert.id_agence_to)
if(!this.isoperateur){

  let agenceTo = this.agences_to_libere.filter(item=>item.id == transfert.id_agence_to)
  let agence_to_name = ''
  if(agenceTo.length >0){
  agence_to_name =agenceTo[0].name
  console.log("Hello i have  rigth to pick")
  this.libereControlls.get("selected_agence_to").setValue(agence_to_name+'('+this.searchAgenceByCode(this.agences_to_libere,transfert.id_agence_to)+')')
  }else{
    if(this.manager_info.level == 2 && this.agencesFrom.length == 1){
      this.updateControlls.get('id_agence_to').setValue(this.agencesFrom[0].id)
      this.libereControlls.get("selected_agence_to").setValue(this.agencesFrom[0].name+'('+this.searchAgenceByCode(this.agences_to_libere,this.agencesFrom[0].id)+')')
    }
    console.log("Hello i have not rigth to pick")
  }
//this.libereControlls.get("selected_agence_to").setValue(agence_to_name+'teest')
 // this.libereControlls.get("selected_agence_to").setValue(agence_to_name+'('+this.searchAgenceByCode(this.agences_to_libere,transfert.id_agence_to)+')')
}
else{
  this.libereControlls.get("selected_agence_to").setValue(this.agence.name+'('+this.searchAgenceByCode(this.initialAgences,this.agence.id)+')')
}

if(this.searchAgenceByCode(this.agences_to_libere,transfert.id_agence_to)){
  let body={
    "id":transfert.id,
    "id_agence_to":transfert.id_agence_to
  }
  console.log("G to get id agence to")
  this.getCurrentTransfertPickerDistribution(body)
}else if(this.agencesFrom.length==1){
  let body={
    "id":transfert.id,
    "id_agence_to":this.agencesFrom[0].id
  }
  console.log("G to get id agence to")
  this.getCurrentTransfertPickerDistribution(body)
}
  if(this.isoperateur){
    let body = {
      "id":transfert.id,
      "id_agence_to":this.agence.id
    }
    this.getCurrentTransfertPickerDistribution(body)
  }

   // console.log(transfert);{size: 'lg', windowClass: 'modal-xl'}
    this.modal.open(x,{ariaLabelledBy : 'modal-basic-title', size: 'xl',backdrop:'static',keyboard:false})
    .result.then( (result:any)=>{
        this.fermer = `closed with :${result}`
    },(reason)=>{
      this.fermer = `Dismissed ${this.dism(reason)}`;
    }
    );
  }

    openModal(x,clause,transfer){
      this.bankOperationOrigine="bimbank"
      this.current_demande_retrait={}
      this.depoControlls.reset();
      this.transfertControlls.reset();
      this.addFactureControlls.reset()
      this.veriBankAccountControls.patchValue({
        last_name:" ",
      })
        if(clause == 1){
          if(!this.isoperateur){
            if(this.agencesFrom.length == 1){
              this.addFactureControlls.patchValue({
                id_agence_from: this.agencesFrom[0].id,
                selected_agence_from:this.agencesFrom[0].name+'('+this.agencesFrom[0].code_agence+')'
              })
            }
          }
        }
        if(clause == 'depot'){
          if(!this.isoperateur){
            if(this.current_manager_agencies.length == 1){
              this.depoControlls.patchValue({
                id_agence_from: this.current_manager_agencies[0].id,
                selected_agence_from:this.current_manager_agencies[0].name+'('+this.current_manager_agencies[0].code_agence+')'
              })
            }
          }
        }

        if(clause == 'addtransfert'){
          if(!this.isoperateur){
            if(this.agencesFrom.length == 1){
              this.depoControlls.patchValue({
                id_agence_from: this.agencesFrom[0].id,
                selected_agence_from:this.agencesFrom[0].name+'('+this.agencesFrom[0].code_agence+')'
              })
            }
          }
        }
        if(clause == 'updateDepot'){
          console.log("Hello World update Depot")

          this.depoControlls.patchValue({
            id:transfer.id,
            id_agence_from:transfer.id_agence_from,
            id_agence_to:transfer.id_agence_to,
            amount:transfer.amount,
            phone_beneficaire:transfer.phone_beneficaire
          })
          let agence_to = this.initialAgences.filter(item=>item.id==transfer.id_agence_to)
          let agence_from = this.initialAgences.filter(item=>item.id == transfer.id_agence_from)
          if(agence_to.length > 0 && agence_from.length>0){
            this.depoControlls.patchValue({

              selected_agence_to:agence_to[0].name+'('+agence_to[0].code_agence+')',
              selected_agence_from:agence_from[0].name+'('+agence_from[0].code_agence+')'
            })
          }

        }
        if(clause =='bank_retrait_confirm_pwd'){
          this.current_demande_retrait = transfer
        }
        if(clause =='mauripay_retrait_confirm_pwd'){
          this.current_demande_retrait = transfer
        }
        if(clause =='cadorim_retrait_confirm_pwd'){
          this.current_demande_retrait = transfer
        }


      console.log(this.ag_from)
      //this.depoControlls.get('id_agence_from').setValue = this.ag_from
      if(clause=='bankoperation'){
        this.resetBankOperationModal();
        if(!this.isoperateur){
            if(this.agencesFrom.length == 1){
              this.banKControls.patchValue({
                id_agence_from: this.agencesFrom[0].id,
                selected_agence_from:this.agencesFrom[0].name+'('+this.agencesFrom[0].code_agence+')'
              })
            }
          }
        this.modal.open(x,{ariaLabelledBy : 'modal-basic-title',backdrop:'static',keyboard:false,size:'xl'})
      .result.then( (result:any)=>{
          this.fermer = `closed with :${result}`

      },(reason)=>{
        this.fermer = `Dismissed ${this.dism(reason)}`;
        this.depoControlls.reset()
      }
      );
      }
      // else{
      //   this.modal.open(x,{ariaLabelledBy : 'modal-basic-title',backdrop:'static',keyboard:false,})
      // .result.then( (result:any)=>{
      //     this.fermer = `closed with :${result}`

      // },(reason)=>{
      //   this.fermer = `Dismissed ${this.dism(reason)}`;
      //   this.depoControlls.reset()
      // }
      // );
      // }

      else if(clause=='mauripayoperation'){
        this.resetMauripayOperationModal();
        if(!this.isoperateur){
            if(this.agencesFrom.length == 1){
              this.mauripayControls.patchValue({
                id_agence_from: this.agencesFrom[0].id,
                selected_agence_from:this.agencesFrom[0].name+'('+this.agencesFrom[0].code_agence+')'
              })
            }
          }
        this.modal.open(x,{ariaLabelledBy : 'modal-basic-title',backdrop:'static',keyboard:false,size:'xl'})
      .result.then( (result:any)=>{
          this.fermer = `closed with :${result}`

      },(reason)=>{
        this.fermer = `Dismissed ${this.dism(reason)}`;
        this.depoControlls.reset()
      }
      );
      }else if(clause=='cadorimoperation'){
        this.resetCadorimOperationModal();
        if(!this.isoperateur){
            if(this.agencesFrom.length == 1){
              this.cadorimControls.patchValue({
                id_agence_from: this.agencesFrom[0].id,
                selected_agence_from:this.agencesFrom[0].name+'('+this.agencesFrom[0].code_agence+')'
              })
            }
          }
        this.modal.open(x,{ariaLabelledBy : 'modal-basic-title',backdrop:'static',keyboard:false,size:'xl'})
      .result.then( (result:any)=>{
          this.fermer = `closed with :${result}`

      },(reason)=>{
        this.fermer = `Dismissed ${this.dism(reason)}`;
        this.depoControlls.reset()
      }
      );
      }else{
        this.modal.open(x,{ariaLabelledBy : 'modal-basic-title',backdrop:'static',keyboard:false,})
      .result.then( (result:any)=>{
          this.fermer = `closed with :${result}`

      },(reason)=>{
        this.fermer = `Dismissed ${this.dism(reason)}`;
        this.depoControlls.reset()
      }
      );
      }

    }

    callParentFunction() {
      console.log("Fonction du parent appelée depuis le composant enfant");
    }

    openLiberationFacture(x,transfert,){

       //return 1
    this.pickFactureControls.get('id').setValue(transfert.id);
    this.apiService.getTransferDetails(transfert.id).subscribe(res=>{
      this.current_transfer = res.transfer;
      transfert = this.current_transfer
      console.log("Details Transfert",this.current_transfer);
    },error =>{ console.log(error)},
    ()=>{
      this.modal.dismissAll('modal')

    this.pickFactureControls.get('id').setValue(transfert.id);
    this.pickFactureControls.get('amount').setValue(transfert.amount);
    this.pickFactureControls.get("phone_sender").setValue(transfert.phone_sender)
    this.pickFactureControls.get("fee").setValue(transfert.fee)
    this.pickFactureControls.get("id_agence_from").setValue(transfert.id_agence_from)
    this.pickFactureControls.get("reference_facture").setValue(transfert.reference_facture)
    //this.factureControlls.get("type").setValue(transfert.type)
    this.pickFactureControls.get("id_agence_to").setValue(transfert.id_agence_to)
    this.pickFactureControls.get("type_transfert").setValue(transfert.type_transfert)


    let agenceTo= this.initialAgences.filter(item=>item.id == transfert.id_agence_to )
  //  this.factureControlls.get("selected_agence_to").setValue(agenceTo[0].name+"("+this.searchAgenceByCode(this.initAgences,transfert.id_agence_to)+")")
  if(agenceTo.length >0){
    this.curent_facture_type = agenceTo[0].type
    this.pickFactureControls.get('type').setValue(this.curent_facture_type)
  }else{
    this.curent_facture_type = ''
  }

   let agencefrom= this.initialAgences.filter(item=>item.id == transfert.id_agence_from )
   console.log("Agence s facture ",this.initialAgences, "agence from ",agencefrom)
  //  this.factureControlls.get("type").setValue(agenceTo[0].type)

  this.pickFactureControls.get("selected_agence_from").setValue(agencefrom[0].name+"("+agencefrom[0].code_agence+")")
  console.log("facture body ", this.pickFactureControls.value)

  let body={
    "id":transfert.id,
    "id_agence_to":transfert.id_agence_to,

  }
  console.log("G to get id agence to")
  this.getCurrentTransfertPickerDistribution(body)



    this.modal.open(x,{ariaLabelledBy : 'modal-basic-title', size: 'l',backdrop:'static',keyboard:false})
    .result.then( (result:any)=>{
        this.fermer = `closed with :${result}`
    },(reason)=>{
      this.pickFactureControls.reset()
      this.fermer = `Dismissed ${this.dism(reason)}`;
    }
    );
    })


    console.log(this.ag_from)
    //this.depoControlls.get('id_agence_from').setValue = this.ag_from
    this.modal.open(x,{ariaLabelledBy : 'modal-basic-title',backdrop:'static',keyboard:false})
    .result.then( (result:any)=>{
        this.fermer = `closed with :${result}`
    },(reason)=>{
      this.fermer = `Dismissed ${this.dism(reason)}`;
    }
    );
  }

    getSearchClause($event){
      console.log("paste phone benef ", $event.target.value);
      console.log("paste phone benef 1 ", this.searchtransfertControls.value);
     // this.srch = $event.target.value
      if($event.target.value.length >= 8){
        $event.target.value=$event.target.value.slice(0,8);
        this.srch=$event.target.value;
        this.searchtransfertControls.patchValue({
          clause:this.srch
        })
       }
    }

    getTypes(){
      this.apiService.getTransfertType().subscribe(
        (res:any)=>{
          this.typeTrans = res.TransferType ;
          console.log(res)
        },(error)=>{},
        ()=>{})
      }


    AllAgencies(){
      this.apiService.allAgencies().subscribe(
        res=>{
          this.agences =res.data;
          this.initialAgences=res.data
          this.deposant_agences = this.initialAgences.filter(item=> item.active == 1 && (item.type == 'transfert' || item.type == 'api'  ))
          this.agences = this.initialAgences.filter(item=> item.active == 1 && (item.type == 'transfert'  ))
          this.initAgences = res.data
          this.initAgences = this.initAgences.filter(item=> item.active == 1 && (item.type == 'transfert'  ))

          console.log(res);
            this.agences_normal = this.initialAgences.filter(item=> item.active == 1 && (item.type == 'transfert' ))

        },
        (error)=>{console.log(error)},
        ()=>{
          if(localStorage.getItem('agence') !== "undefined"){
            console.log(JSON.parse(localStorage.getItem('agence')))
            this.agences = this.agences.filter(item => (item.id !== JSON.parse(localStorage.getItem('agence')).id))
            let credit_agence =[]
             credit_agence = this.initialAgences.filter(item=>(item.active == 1 && item.id_manager == this.user.id && item.type =='credit'))
            if(credit_agence.length >0){
              this.agence_credit  = credit_agence[0]
              this.agence_credit_id  = credit_agence[0].id
            }
          }else{
            let credit_agence =[]
             credit_agence = this.initialAgences.filter(item=>(item.active == 1 && item.type =='credit'))
            if(credit_agence.length >0){

              this.agence_credit  = credit_agence[0]
              this.agence_credit_id  = credit_agence[0].id
            }
          }
          console.log("agENCE DE CREDIT POUR MANAGER ",this.agence_credit, "all ",this.initialAgences)
          this.agences= this.agences.filter(item => (item.active == 1 && (item.type == 'transfert' )))

          if(this.isadmin || this.is_sous_admin){
            this.agences_facture = this.initialAgences.filter(item => (item.active == 1 && (item.type =='snde' || item.type =='somelec' || item.type =='wifi') ))
            console.log("AGENCES FACTure ", this.agences_facture)
          }


        }
      )
    }
    addTransfert(){


      let idfrom;
      if(!this.isoperateur){
        if(this.issupervisor || this.ismanager){
          if(this.current_manager_agencies.length ==1){
            idfrom =this.current_manager_agencies[0].id
            this.transfertControlls.get('id_agence_from').setValue(idfrom)
          }else{
            idfrom = this.searchAgenceById(this.current_manager_agencies,this.transfertControlls.get('selected_agence_from').value)
            if(idfrom){
              this.transfertControlls.get('id_agence_from').setValue(idfrom)
            }
          }
        }else{
          idfrom = this.searchAgenceById(this.agences_normal,this.transfertControlls.get('selected_agence_from').value)
          if(idfrom){
            this.transfertControlls.get('id_agence_from').setValue(idfrom)
          }
        }
        if(!idfrom){

          this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
          this.transfertControlls.get('selected_agence_from').reset()
          return 1
        }

      }
      if(parseFloat(this.transfertControlls.get("amount").value)> 300 && parseFloat(this.transfertControlls.get("fee").value)<10 ){
        this.notifyService.infoNotify('top','center',this.translate.instant('Veillez saisir le frai doit etre superieur ou egale a 10'));
        return 1;
      }
      /*if(parseFloat(this.transfertControlls.get("amount").value)> 300 && parseFloat(this.transfertControlls.get("fee").value)==0 ){
        this.notifyService.infoNotify('top','center',this.translate.instant('Veillez saisir le frai est null'));
        return 1;
      }*/
        let idAgenceTo = this.searchAgenceById(this.initialAgences,this.transfertControlls.get('selected_agence_to').value)
        if(idAgenceTo){
          this.transfertControlls.get('id_agence_to').setValue(idAgenceTo)
        }
        if(!idAgenceTo){
          this.transfertControlls.get('selected_agence_to').reset()
          this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_to'))
          return 1
        }


      console.log("code agence")
      console.log(this.transfertControlls.value)
      //return 1;
      if(parseFloat(this.transfertControlls.get("amount").value)> this.transfertService.MONTANT_MAX){

        this.notifyService.infoNotify('top','center',this.translate.instant('Message.amount_send_limit'));
        return 1;
      }else if((parseFloat(this.transfertControlls.get("amount").value) >= this.transfertService.MONTANT_NEEDED_ALL_INFOS)&&
      ((this.transfertControlls.get("nni_sender").value == "" || this.transfertControlls.get("nni_sender").value == undefined)||

      (this.transfertControlls.get("last_name_sender").value == "" || this.transfertControlls.get("last_name_sender").value == undefined)
      )){
        this.notifyService.infoNotify('top','center',this.translate.instant('Message.enter_exped_infos'));
        return 1;
      }
      else{
        if(this.isoperateur){
          this.transfertControlls.patchValue({
            id_agence_from:this.ag_from,
           })
        }else{
          this.ag_from = this.transfertControlls.get('id_agence_from').value
        }





        if(this.transfertControlls.get('type_transfert').value == "" || this.transfertControlls.get('type_transfert').value == null ){
          console.log("TYPE TRANSFERT PATCH VALUE 1")
          this.transfertControlls.patchValue({
            type_transfert:"normal",
           })

        }
        console.log(this.transfertControlls.value)

        if(!this.transfertService.transferControlFraisByAmount(parseFloat(this.transfertControlls.get('fee').value),parseFloat(this.transfertControlls.get('amount').value))){
        this.notifyService.infoNotify('top','center',this.translate.instant('Message.enter_valide_frais'));
        return 1 ;
      }
      if((this.transfertControlls.get('id_agence_from').value != this.transfertControlls.get('id_agence_to').value)){
        console.log("ADD TRANSFERT")

        let message=""
        this.transfertControlls.disable()
      this.apiService.addTransfert(this.transfertControlls.value).subscribe(
       res=>{
         console.log(res);
         ///this.apiService. refreshData(this.agence ,this.transfertControlls.value);


       },(error)=>{
         this.transfertControlls.enable()
         message = error.error.message

         this.notifyService.servorErrorNotify(error.status,error)
         //this.transfertControlls.reset()
         console.log("ERREUR IN ADD TRANSFERT",error)
         //return 1;
       },()=>{
        this.modal.dismissAll('close');
        this.notifyService.successNotify('top','center',this.translate.instant('Message.transfert_add_success'));
        this.transfertControlls.enable()
        this.transfertControlls.reset()
        //this.getAgencies();
        //this.notifyService.getRefreshedData(1);
        //location.reload();
        if(!this.isoperateur){
          this.getAgencies()
        }
        this.AllAgencies();
        //this.getTypes();

        this.operations= [] ;
        this.apiService.refreshTransfer(true);
       });
      }else{
        this.notifyService.errorNotify('top','center',"Impossible de faire un trasnfert vers le meme agence");
      }
      // this.router.navigate(['/caisse-operateurx']);

      }

    }



    getAmount(val:any){
      if(val.target.value >= this.transfertService.MONTANT_NEEDED_ALL_INFOS ){
        this.kyc= true;
      }else{
        this.kyc=false;
      }

    }

    search1(x,y,t){

      if(!this.ag_from){
        this.ag_from=0;
      }
      if(!this.searchtransfertControls.get('clause').value || this.searchtransfertControls.get('clause').value==null ){
        this.notifyService.warningNotify("top","center",this.translate.instant('Message.enter_clause_search'))
        return 1;
      }
      let body ={
        searchclause:this.searchtransfertControls.get('clause').value,
        useragency:this.ag_from,
        managerid:this.managerId
      }

      this.SerachTransfertInWaiting(body,x,y)

      //this.open(x,t);
    }
    private dism(reason:any){
       if(reason === ModalDismissReasons.ESC){
           return 'by pressing ESC';
       }else if(reason === ModalDismissReasons.BACKDROP_CLICK){
        return 'by clicking on a backdrop';
       }else{
         return `with : ${reason}`;
       }
    }

 //refresh:boolean= true;
 /*refreshTransfer() {

    this.apiService.refreshTransfer(this.refresh);
  }*/
    SerachTransfertInWaiting(clause,x,y){
      this.initlistes=[]
      this.listes=[]
      this.apiService.getSearchTransfertByClause(clause).subscribe(
        res=>{
          this.initlistes = res.transfer
          console.log(res)
        },
        (error)=>{
          console.log(error)
          this.notifyService.servorErrorNotify(error.status,error)
        },
        ()=>{
          //this.checkHaveToLibereTransfert(this.listes[0])
          if(this.initlistes.length<= 0  ){
            this.notifyService.infoNotify('top','center',this.translate.instant('Message.transfert_benef_not_found'))
            return 1;
          }

          this.listes = this.initlistes;
          if(this.initlistes.length >1){
            this.open(x,0)
            return 1;
          }
          //this.open(x,0)
          if(this.isoperateur){
            console.log("Le modal a ouvrir ",x)
            if(this.initlistes.length == 1){
              if(this.agence.id != this.listes[0].id_agence_from   && this.listes[0].type_transfert=='normal' ){
                console.log("Le modal a ouvrir ",x)

               this.openLiberationModal(y,this.listes[0])
              }else{
                this.open(x,0)
              }
            }else{
              this.open(x,0)
            }
          }
          else if(this.manager_number_agencies == 1 && this.agencesFrom.length == 1){
            if(this.agencesFrom[0].id != this.listes[0].id_agence_from  && this.listes[0].type_transfert=='normal'){
              if(this.agences_to_libere.length == 1){
                this.libereControlls.patchValue({
                  selected_agence_to:this.agences_to_libere[0].name+'('+this.agences_to_libere[0].code_agence+')',
                  id_agence_to:this.agences_to_libere[0].id
                })
              }

              this.openLiberationModal(y,this.listes[0])
            }
            else{
              this.open(x,0)
            }
          }
          else{
            this.open(x,0)
          }

          console.log(this.listes)
        }
      )
    }

    Libere(){
      if(this.current_transfer.status == 'waiting'){
      if(this.current_transfer.type_transfert != 'paiement_facture'){
        console.log(this.libereControlls.value)
     // return 1;
      let id = this.libereControlls.get('id').value
      if(!this.libereControlls.get('selected_agence_to').value){
        this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_to'))
        return 1;
      }
      let idAgenceTo
     if(this.current_transfer.type_transfert != 'paiement_facture'){  idAgenceTo = this.searchAgenceById(this.agences_to_libere,this.libereControlls.get('selected_agence_to').value)
      if(idAgenceTo){
        this.libereControlls.get('id_agence_to').setValue(idAgenceTo)
        this.ag_from = this.libereControlls.get('id_agence_to').value
        console.log(this.libereControlls.value)

      }}else{
        idAgenceTo = this.searchAgenceById(this.agences_facture,this.libereControlls.get('selected_agence_to').value)
      if(idAgenceTo){
        this.libereControlls.get('id_agence_to').setValue(idAgenceTo)
        this.ag_from = this.libereControlls.get('id_agence_to').value
        console.log(this.libereControlls.value)
      }}
      if(!this.isoperateur){
        if(!idAgenceTo){
          console.log(this.libereControlls.value)
          this.libereControlls.get('selected_agence_to').reset()
          this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_to'))
          return 1
        }
      }
     // console.log(id)
      if(parseFloat(this.libereControlls.get('amount').value ) >= this.transfertService.MONTANT_NEEDED_ALL_INFOS &&
      ((this.libereControlls.get("nni_beneficiare").value == "" || this.libereControlls.get("nni_beneficiare").value == undefined)||

      (this.libereControlls.get("last_name_beneficiare").value == "" || this.libereControlls.get("last_name_beneficiare").value == undefined)
      )){
        this.notifyService.infoNotify('top','center',this.translate.instant('Message.enter_benef_infos'));
        return 1
      }
      if(!this.ag_from){
       /* console.log("PAS DE AG FROM")
        console.log(this.ag_from)

        let currentTransfert = this.listes.filter(item => (item.id == id))
        console.log(currentTransfert)
        console.log(currentTransfert[0].id_agence_to)
        this.ag_from = currentTransfert[0].id_agence_to
        console.log(this.ag_from)*/
        this.ag_from = this.libereControlls.get('id_agence_to').value
      }
      if(!this.ag_from){}

      console.log(id);
      console.log(this.ag_from);
      console.log(this.libereControlls.value)
      //return 1
      this.libereControlls.disable()
      this.apiService.retraitTransfert(this.libereControlls.value,this.ag_from).subscribe(
       res=>{
         console.log(res);
       },(error)=>{
         this.libereControlls.enable()
         this.notifyService.servorErrorNotify(error.status,error)
          //this.notifyService.errorNotify('top','center',"Impossible de liberer ce transfert \n le plafond est depasse")
         console.log(error)
        },
       ()=>{
         this.libereControlls.reset()
         this.libereControlls.enable()
         this.modal.dismissAll("close")
         this.apiService.refreshTransfer(true)
        this.notifyService.successNotify('top','center',this.translate.instant('Message.transfert_pick_success'));

       }
     )
    }else{
      console.log(" pick facture ",this.pickFactureControls.value)
      this.pickFactureControls.disable()
      this.apiService.retraitTransfert(this.pickFactureControls.value,this.pickFactureControls.get('id_agence_to').value).subscribe(
        res=>{
          console.log(res);
        },(error)=>{
          this.pickFactureControls.enable()
          //this.notifyService.errorNotify('top','center',"Impossible de liberer ce transfert \n le plafond est depasse")
          console.log(error)
          this.notifyService.servorErrorNotify(error.status,error)
         },
        ()=>{
          this.pickFactureControls.reset()
          this.pickFactureControls.enable()
          this.modal.dismissAll("close")
          this.apiService.refreshTransfer(true)
         this.notifyService.successNotify('top','center',this.translate.instant('Message.transfert_pick_success'));

        }
      )
    }
      }
      /*else{
        this.pickTransfertSuspended()
      }*/
    }

    deleteTransfert(){
      console.log(this.deleteControlls.value)
      var id =this.deleteControlls.controls['id'].value
      this.deleteControlls.disable()
      this.apiService.deleteTransfert(this.deleteControlls.value,id).subscribe(
        res=>{
          console.log(res);
        },(error)=>{
          //this.notifyService.errorNotify('top','center',"L'annulation de transfert a echouer");
          this.deleteControlls.enable()
          console.log(error)
          this.notifyService.servorErrorNotify(error.status,error)
        },()=>{
        //this.getTypes();
          this.deleteControlls.reset();
          this.deleteControlls.enable()
          this.modal.dismissAll('close');
          this.notifyService.successNotify('top','center',this.translate.instant('Message.transfert_annuled'));
        }
      );
    }
    deleteTr(){
      console.log(this.updateControlls.get('id').value)
      console.log(this.updateControlls.value)
        var id =this.updateControlls.controls['id'].value
        this.updateControlls.patchValue({
          comment:this.annuleTransfertForm.get('comment').value
        })
        console.log(this.updateControlls.value)
        this.updateControlls.disable()
        this.apiService.deleteTransfert(this.updateControlls.value,id).subscribe(
          res=>{
            console.log(res);
          },(error)=>{
            this.notifyService.servorErrorNotify(error.status,error)
            //this.notifyService.successNotify('top','center',"L'annulation de transfert a echouer!");
            this.updateControlls.enable()
            console.log(error)
          },()=>{
            //this.getTypes();
            this.updateControlls.reset()
            this.updateControlls.enable()
            this.modal.dismissAll('close');
            this.notifyService.successNotify('top','center',"Transfert supprimé avec succés!");
          }
        );
    }

    updateTransfert(){
      console.log(this.updateControlls.value)
      var id =this.updateControlls.controls['id'].value
     // this.updateControlls.patchValue({
      //  id_agence_from:this.ag_from
     //  })
     if(!this.transfertService.transferControlFraisByAmount(parseFloat(this.updateControlls.get('fee').value),parseFloat(this.updateControlls.get('amount').value))){
      this.notifyService.infoNotify('top','center',this.translate.instant('Message.enter_valide_frais'));
      return 1 ;
    }
       if(parseFloat(this.updateControlls.get("amount").value) > this.transfertService.MONTANT_MAX){

        this.notifyService.infoNotify('top','center',this.translate.instant('Message.amount_send_limit'));
        return 1;
      }else if((parseFloat(this.updateControlls.get("amount").value)>= this.transfertService.MONTANT_NEEDED_ALL_INFOS)&& (this.updateControlls.get("nni_sender").value == "" || this.updateControlls.get("nni_sender").value == undefined )){
        this.notifyService.infoNotify('top','center',this.translate.instant('Message.enter_exped_infos'));
        return 1;

      }
      let idAgenceTo = this.searchAgenceById(this.initialAgences,this.updateControlls.get('selected_agence_to').value)
      if(idAgenceTo){
        this.updateControlls.get('id_agence_to').setValue(idAgenceTo)
        console.log(this.updateControlls.value)

      }
      if(!idAgenceTo){
        this.updateControlls.get('selected_agence_to').reset()
        this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_to'))
        return 1
      }
      let idAgencefrom = this.searchAgenceById(this.initialAgences,this.updateControlls.get('selected_agence_from').value)
      if(idAgencefrom){
        this.updateControlls.get('id_agence_from').setValue(idAgencefrom)
        console.log(this.updateControlls.value)

      }
      if(!idAgencefrom){
        this.updateControlls.get('selected_agence_from').reset()
        this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
        return 1
      }
      let message=""
      this.updateControlls.disable()
      this.apiService.updateTransfert(this.updateControlls.value,id).subscribe(
        res=>{
          console.log(res);
        },(error)=>{
          this.updateControlls.enable()
          message = error.error.message
          /*
          if(message == "plafond_manager"){
           this.notifyService.errorNotify('top','center',"Le plafond avec le gestionnaire est depasse");
          }

          else if(message == "plafond_agence"){
           this.notifyService.errorNotify('top','center',"Le seuil de la caisse est atteint");
          }
          else{
            this.notifyService.errorNotify('top','center',"Erreur technique!");
          }
          */
          console.log(error)
          this.notifyService.servorErrorNotify(error.status,error)
        },()=>{
          //this.getTypes();
          this.updateControlls.reset()
          this.updateControlls.enable()
          this.notifyService.successNotify('top','center',this.translate.instant('Message.transfert.update_success'));
          this.apiService.refreshTransfer(true)
          this.modal.dismissAll('close');
        }
      );
    }

    public  getAgenceFrom(id_agence){

      //console.log(this.agences)

      /*let agencefrom = this.initialAgences.filter(item => item.id == idfrom  );
      console.log(agencefrom)

      if(agencefrom.length >0){
      return agencefrom[0].name;
      }
      return "agence introuvable"*/
      return this.getAgenceNameByManagerPosition(id_agence)

    }

    async getAgencies(){

      //this.apiService.getAgencies().subscribe((res)=>{
        this.apiService.getManagersAndSousPartenairesAgencies(this.managerId).subscribe((res)=>{
        if(res){
          console.log("MANAGER and SOus partener agencies  ",res)
          this.agencesFrom = res.data;
          console.log("This agences From ",this.agencesFrom)
          if(this.ismanager || this.issupervisor){this.agences_facture = res.data.filter(item => (item.active == 1 && (item.type =='snde' || item.type =='somelec' || item.type =='wifi') ))}
         console.log("AGENCES FACTure ", this.agences_facture)

          this.agencesFrom = this.agencesFrom.filter(item=> item.active == 1 && (item.type == 'transfert' || item.type == 'api'))
          this.current_manager_agencies = res.data.concat(res.sous_manager_agencies)
          this.current_manager_agencies = this.current_manager_agencies.filter(item=> item.active == 1 && (item.type == 'transfert' || item.type == 'api'))
          this.agences_origines = this.current_manager_agencies.filter(item=> item.active == 1 && item.type == 'transfert' )
          console.log(this.agences);
          this.agences_to_libere = this.agencesFrom

        }
       },
       (error)=>{
         console.log(error)
         this.notifyService.servorErrorNotify(error.status,error)
        },
       ()=>{

       }
       );
    }

  repartitionPrivilleges(){
    console.log("repartitionPrivilleges")
    this.privilleges.forEach(element => {
        if(element == "add_transfert" || element == "creation_transfert_sortant"){
          this.have_to_create_transfert = true;
        }
        if(element == "update_transfert" ){
          this.have_to_update_transfert = true;
        }
        if(element == "delete_transfert"){
          this.have_to_delete_transfert = true
        }
        if(element == "pick_transfert"){
          this.have_to_pick_transfert= true
        }

        if(element == "reverse_pick_transfer"){
          this.have_to_reverse_pick_transfert= true
        }
        if(element == "confirm_depot_cash"){
          this.have_to_confirm_depot = true;
        }

        if(element == "add_bank_operation"){
          this.have_to_add_operation_bank = true;
        }
        if(element == "add_mauripay_operation"){
          this.have_to_add_operation_mauripay = true;
        }
        if(element == "add_cadorim_operation"){
          this.have_to_add_operation_cadorim = true;
        }
    });
    console.log("The list of privilleges");


    //this.privillegeServices.repartitionPrivilleges(this.privilleges)
  }

  // add depot cash
  addDepotCash(){


    if(!this.isoperateur){
        console.log("depot vlaue form ",this.depoControlls.value)
       // return 1;
        let idfrom = this.searchAgenceById(this.initialAgences,this.depoControlls.get('selected_agence_from').value)
        if(idfrom){
          this.depoControlls.get('id_agence_from').setValue(idfrom)
          console.log()
          this.ag_from = this.depoControlls.get('id_agence_from').value
        }

        if(!idfrom){
          this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
          this.depoControlls.get('selected_agence_from').reset()
          return 1
        }

    }
    let idAgenceTo = this.searchAgenceById(this.initialAgences,this.depoControlls.get('selected_agence_to').value)
        if(idAgenceTo){
          this.depoControlls.get('id_agence_to').setValue(idAgenceTo)
          console.log(this.depoControlls.value)

        }
        if(!idAgenceTo){
          this.transfertControlls.get('selected_agence_to').reset()
          this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
          return 1
        }
    this.depoControlls.patchValue({
      type_transfert:"cash_depot",
      fee:0,
      id_agence_from:this.ag_from
    })
    if(this.depoControlls.get('id_agence_from').value == this.depoControlls.get('id_agence_to').value ){
      this.notifyService.warningNotify('top','center',"Impossible de faire ce depot");
      return 1
    }
    console.log(this.depoControlls.value)
    let message=""
    this.depoControlls.disable()
    this.apiService.addTransfert(this.depoControlls.value).subscribe(
      res=>{
        console.log(res);
      },(error)=>{
        this.depoControlls.enable()
        message = error.error.message

        // if(message == "phone_beneficaire is not valid"){
        //   this.notifyService.errorNotify('top','center',this.translate.instant('Transfert.phone_bf'));
        // }

        /*
        if(message == "plafond_manager"){
         this.notifyService.errorNotify('top','center',"Le plafond avec le gestionnaire est depasse");
        }

        if(message == "plafond_agence"){
         this.notifyService.errorNotify('top','center',"Le seuil de la caisse est atteint");
        }
        if(message == "Caisse Insuffisant"){
          this.notifyService.errorNotify('top','center',"le Solde de la Caisse de retrait est insuffisant");
        }
        */
        //this.depoControlls.reset()
        this.notifyService.servorErrorNotify(error.status,error)
        console.log(error)
      },()=>{
       this.depoControlls.reset()
       this.depoControlls.enable()
       //this.getAgencies();
       if(!this.isoperateur){
         //this.getAgencies()
       }
       //this.AllAgencies();
       //this.getTypes();
       this.notifyService.successNotify('top','center',this.translate.instant('Message.depot_success'));
       this.apiService.refreshTransfer(true)
       this.modal.dismissAll('close');
      });
  }

  // Multi select list pour role

  onItemSelect(item: any) {
    console.log(item);
    let agence_to = item.id
    //role.push(item.name)
    console.log("le role apres itemselect")
    console.log(item)
    this.depoControlls.patchValue({
      idIagence_to:agence_to
    });
  }
  // event when agence deposant selected
  onItemSelectDeposant(item:any){
    console.log(item);
    let agence_to = item.id
    //role.push(item.name)
    console.log("le role apres itemselect :||"+item.id)
    if(this.isoperateur){
      this.depoControlls.patchValue({
        id_agence_from:this.ag_from,
        selected_agence_from:[]
      });
    }
    this.depoControlls.patchValue({
      id_agence_to:agence_to
    });
    console.log(this.depoControlls.value)
  }


   // event when agence deposant selected
   onItemSelectLieuDepot(item:any){
    console.log(item);
    let agence_from = item.id
    //role.push(item.name)
    console.log("le role apres itemselect :||"+item.id)

    this.depoControlls.patchValue({
      id_agence_from:agence_from
    });
    console.log(this.depoControlls.value)
  }

  addTransfertItemSelectedAgenceTo(item:any){
    console.log(item);
    let agence_to = item.id
    //role.push(item.name)
    console.log("le role apres itemselect :||"+item.id)
    if(this.isoperateur){
      this.transfertControlls.patchValue({
        id_agence_from:this.ag_from,
        selected_agence_from:[]
      });
    }
    this.transfertControlls.patchValue({
      id_agence_to:agence_to
    });
    console.log(this.transfertControlls.value)
  }

  addTransfertItemSelectedAgenceFrom(item:any){
    console.log(item);
    let agence_from = item.id
    //role.push(item.name)
    console.log("le role apres itemselect :||"+item.id)
   /* if(this.isoperateur){
      this.transfertControlls.patchValue({
        id_agence_from:this.ag_from,

      });
    }*/
    this.transfertControlls.patchValue({
      id_agence_from:agence_from
    });
    console.log(this.transfertControlls.value)
  }

  public  agenceInConnectedZone(agence) {
    let agenceto=[];
    agenceto = this.initialAgences.filter(item=>(item.id == agence))
    console.log("IS NOT CONNECTED")
    console.log(agenceto)
    if(agenceto[0].zone == "connected"){
      return true;
    }else{
      return false;
    }
    console.log("IS NOT CONNECTED ::|| "+this.InConnectedZone)
  }
  onFilterChange(item){
    let dataFiltered
    console.log(item)
    console.log(this.current_manager_agencies[0])
   // this.current_manager_agencies = [this.current_manager_agencies[0]]
    console.log("Hello I Filter by agence code");
    if(!item){
       dataFiltered=this.current_manager_agencies;
       console.log("Hello I Filter by agence code IN IF SECTION")
  }
    else
    {
      console.log("Hello I Filter by agence code IN ELSE  SECTION")
       item=item.toLowerCase();
      /* dataFiltered=this.current_manager_agencies.filter(x=>x.name.toLowerCase()
          ==item)*/
          dataFiltered = this.current_manager_agencies.filter(elmt => elmt.code_agence == item );
        console.log(dataFiltered)
        let result = []
        result = dataFiltered.filter(elmt => elmt.name == item );
        result=dataFiltered.filter(x=>x.name.toLowerCase()
          .indexOf(x.name)==0)
        console.log("the result"+dataFiltered[0].name)
        console.log(result)
        this.current_manager_agencies = result
         // this.current_manager_agencies = dataFiltered
    }

  }
  prepareMultiselectList(){


    this.dropdownSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 4,
      allowSearchFilter: true,
      enableCheckAll:true,

      noDataAvailablePlaceholderText:'No Data',


    };

  }

  getUserIdsFirstWay($event){
    let userId = (<HTMLInputElement>document.getElementById($event.target.id)).value;

    if (userId.length > 2) {
     // if ($event.timeStamp - this.lastkeydown1 > 200) {
        this.searchFromArray(this.current_manager_agencies, userId);
      //}
    }
  }

  searchFromArray(arr, regex) {

    let matches = [];
    for (let title of arr) {
      if (String(title).match(regex)) {
        matches.push(title);
      }
    }
    return matches;
  }

  searchAgenceById(list,id:String){
    //let str = id.split(' :')
    console.log("Resultat du Split",id)
    let initial_agence_name:String = id;
    let new_str = initial_agence_name.substring(initial_agence_name.length-8)
    let real_code = new_str.replace(/[{()}]/g, '');
    console.log("The new Search filtre of agence Name",new_str)
    console.log("New Code ",real_code)

    //console.log(str)
    let newListe =[];
    newListe = list.filter(item=>item.code_agence == real_code);
    console.log("Inititialisation liste",list)
    console.log("new liste",newListe)
    if(newListe.length>0){
      console.log("new liste",newListe)
      //console.log("str1 "+str[0])
      console.log(newListe)
      return newListe[0].id
    }
    else return null
  }

  getAgenceFromSelect($event,list,id){
    let userId = (<HTMLInputElement>document.getElementById($event.target.id)).value;

    if (userId.length > 2) {
     // if ($event.timeStamp - this.lastkeydown1 > 200) {
        this.searchFromArray(list, userId);
      //}
    }
  }

  searchAgenceByCode(list,id:String){
    //let str = id.split(' :')
    console.log("Resultat du Split",id)
    console.log("La liste sur lquel filtrer",list)
    //console.log(str)
    let newListe =[];
    newListe = list.filter(item=>item.id == id);
    if(newListe.length>0){
      console.log(newListe)
      //console.log("str1 "+str[0])
      console.log(newListe)
      return newListe[0].code_agence
    }
    else return null
  }


  getCurrentTransfertPickerDistribution(body){

    this.apiService.getCurrentTransfertPickerDistribution(body).subscribe(
      res=>{
        this.distributions  = res;
        console.log(this.distributions)
        this.transfert_gain = res.picker_distribution
      },
      (error)=>{
        this.notifyService.servorErrorNotify(error.status,error)
      },
      ()=>{
        console.log("Le gain sur le transfert")
        console.log(this.transfert_gain)
        this.libereControlls.get('gain').setValue(this.transfert_gain)
        this.pickFactureControls.get('gain').setValue(this.transfert_gain)
      }
    )
  }

  getDisTribution(event){
    console.log("We are here to get Distributions")
    console.log(event.target.value);
    console.log(this.libereControlls.value
    )
    this.getCurrentTransfertPickerDistribution(this.libereControlls.value)
  }

  getAgenceFromTolibere($event,list,id){

    let userId = (<HTMLInputElement>document.getElementById($event.target.id)).value;
    this.transfert_gain = 0;
          this.libereControlls.get('gain').setValue(this.transfert_gain)
    if (userId.length > 2) {
     // if ($event.timeStamp - this.lastkeydown1 > 200) {
       let list1= this.searchFromArray(list, userId);

        let code_agence =this.getRealCodeOfAgence(String($event.target.value))
        if(code_agence.length == 6){
          let idAgenceTo = this.searchAgenceById(this.agences_to_libere,code_agence)
          if(idAgenceTo){
            let body={
              "id":this.libereControlls.get('id').value,
              "id_agence_to":idAgenceTo
            }
            console.log("on entre dans get Pick Distribution",body)
            this.getCurrentTransfertPickerDistribution(body)
          }
        }

        console.log(list)
      //}
    }
  }

  getTransfertAgenceFromIdManager(id_agence_from){
    let list=[];
    console.log("filtre to troouve initial from",this.initialAgences)
    list = this.initialAgences.filter(item=> item.id == id_agence_from);
    if(list.length>0){
      console.log("filtre to troouve id agence from",list)
      return list[0].id_manager;
    }else{
      return 0;
    }
  }

  getRealCodeOfAgence(str){
    console.log("Resultat du Split",str)
    let initial_agence_name:String = str;
    let new_str = initial_agence_name.substring(initial_agence_name.length-8)
    let real_code = new_str.replace(/[{()}]/g, '');
    console.log("The new Search filtre of agence Name",new_str)
    console.log("New Code ",real_code)
    return real_code;

  }

  transfererCredit(){
    if(this.agence_credit_id == 0){
      console.log("Agence credit nexiste pas")
      this.notifyService.infoNotify('top','center',this.translate.instant('Message.agence_credit_not_found'))
      return 1
    }
    console.log("agence credit ",this.agence_credit, " agence_credit_id ",this.agence_credit_id)
    this.creditControlls.get('id_agence_to').setValue(this.agence_credit_id)
    console.log("CREDI body ",this.creditControlls.value)

    let operateur_phone_code = "";
    let initial_phone_benef:String = String(this.creditControlls.get('phone_beneficiare').value);

    let original_phone_benef = initial_phone_benef.substring(initial_phone_benef.length-8)
    if(original_phone_benef.charAt(0)=='4'){
      operateur_phone_code = 'creditMauritel'
    }
    if(original_phone_benef.charAt(0)=='3'){
      operateur_phone_code = 'creditMattel'
    }
    if(original_phone_benef.charAt(0)=='2'){
      operateur_phone_code = 'creditChinguitel'
    }
    let montant=this.creditControlls.get('amount').value
    console.log("CODE OPERAEUR IS",operateur_phone_code)
    console.log("Envoi de credit",original_phone_benef.toString());
    if(this.isoperateur){
    this.creditControlls.patchValue({
      id_agence_from:this.agence.id
    })
  }else{
    let idfrom = this.searchAgenceById(this.initialAgences,this.creditControlls.get('selected_agence_from').value)
    if(idfrom){
      this.creditControlls.get('id_agence_from').setValue(idfrom)
      console.log("AGENCE FROM ",idfrom)

    }else{


      this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
      this.creditControlls.get('selected_agence_from').reset()
      return 1
    }
  }

    this.apiService.doTransfertCredit(original_phone_benef,montant,operateur_phone_code).subscribe(
      res=>{

      },
      (error)=>{
        this.notifyService.servorErrorNotify(error.status,error)
        console.log("Erreur transfert credit",error)
      },
      ()=>{
        console.log("Transfert credit valide")
        this.apiService.refreshTransfer(true)
      }
    )

    this.sendCreditTransfert(this.creditControlls.value)
  }

  sendCreditTransfert(body){
    this.creditControlls.disable()
    this.apiService.sendCreditTransfert(body).subscribe(
      res=>{
        this.credit_message_retour = res
        console.log("Retour envoie credit",res)
      },
      (err)=>{
        console.log(err)
        /*
        if(err.error.message == "plafond_agence"){
          this.notifyService.errorNotify('top','center',"Le seuil de la caisse est atteint");
         }else{
          this.notifyService.errorNotify('top','center',"l'envoie a ehouer");
         }
         */
        this.notifyService.servorErrorNotify(err.status,err)
         this.creditControlls.enable()
      },
      ()=>{
        this.apiService.refreshTransfer(true)
        this.notifyService.successNotify('top','center',this.translate.instant('Message.depot_success'));
        this.creditControlls.reset()
        this.creditControlls.enable()
        this.modal.dismissAll('close');
      }

    )
  }

  addPaiementFacture(){
    console.log(this.addFactureControlls.valid)


    if(!this.isoperateur){
      let idfrom = this.searchAgenceById(this.initialAgences,this.addFactureControlls.get('selected_agence_from').value)
      if(idfrom){
        this.addFactureControlls.get('id_agence_from').setValue(idfrom)
        console.log("AGENCE FROM ",idfrom)

      }else{
        this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
        this.addFactureControlls.get('selected_agence_from').reset()
        return 1
      }
    }else{

      this.addFactureControlls.get('id_agence_from').setValue(this.agence.id)
    }

    if(!this.transfertService.transferControlFraisByAmount(parseFloat(this.addFactureControlls.get('fee').value),parseFloat(this.addFactureControlls.get('amount').value))){
      this.notifyService.infoNotify('top','center',this.translate.instant('Message.enter_valide_frais'));
      return 1 ;
    }
    console.log("Facture Payment ",this.addFactureControlls.value)
    this.addFactureControlls.disable()
    this.apiService.payementFacture(this.addFactureControlls.value).subscribe(
      res=>{
        console.log(res)
      },
      (error)=>{
        console.log(error)
        /*

        if(error.error.code == 'snde' || error.error.code == 'wifi' || error.error.code == 'somelec'){
          let message :String=error.error.code
          this.notifyService.errorNotify('top','center',"le service de "+message.toUpperCase()+"n'est pas disponible");
        }else{
          this.notifyService.errorNotify('top','center',"Payment facture a echouer");
        }
        */
        this.addFactureControlls.enable()
        this.notifyService.servorErrorNotify(error.status,error)

      },
      ()=>{
        this.notifyService.successNotify('top','center',this.translate.instant('Message.facture_add_success'));

        this.addFactureControlls.reset()
        this.addFactureControlls.enable()
        this.apiService.refreshTransfer(true)
        this.modal.dismissAll('close');
      }
    )


  }


  pickTransfertSuspended(){


    let id = this.libereControlls.get('id').value
    this.ag_from = this.libereControlls.get('id_agence_to').value
    let idAgenceTo = this.searchAgenceById(this.agences_to_libere,this.libereControlls.get('selected_agence_to').value)
  if(idAgenceTo){
    this.libereControlls.get('id_agence_to').setValue(idAgenceTo)
    this.ag_from = this.libereControlls.get('id_agence_to').value
    console.log(this.libereControlls.value)

  }
  if(!this.isoperateur){
    if(!idAgenceTo){
      console.log(this.libereControlls.value)
      this.libereControlls.get('selected_agence_to').reset()
      console.log("AGENCE TO LIBERES",this.agences_to_libere)
      this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_to'))
      return 1
    }
  }else{
    this.libereControlls.get('id_agence_to').setValue(this.agence.id)
    this.ag_from = this.libereControlls.get('id_agence_to').value
    console.log(this.libereControlls.value)
  }
    console.log(id);
    console.log(this.ag_from);
    this.libereControlls.patchValue({
      fee:0
    })
    console.log("Pick Transfert suspended ",this.libereControlls.value)

    //return 1
    this.libereControlls.disable()
    this.apiService.pickTransfertSuspended(this.libereControlls.value,this.libereControlls.get('id_agence_to').value).subscribe(
     res=>{
       console.log(res);
     },(error)=>{
       console.log(error)

       this.libereControlls.enable()
       this.notifyService.servorErrorNotify(error.status,error)
       //this.notifyService.errorNotify('top','center',"La confirmation a echouer");
      },
     ()=>{
       if(this.isoperateur){
        //this.getAccountCashDepot(this.agence.number_account)
       }
       if(this.ismanager || this.issupervisor){
        // this.getManagerDepotsCash(this.manager_info.id);
       }
       /*if(!this.ismanager && !this.isoperateur){
        this.getManagerDepotsCash(this.user.id_parent);
       }*/
       this.libereControlls.reset()
       this.libereControlls.enable()
       this.modal.dismissAll("close")
      this.notifyService.successNotify('top','center',this.translate.instant('Message.transfert_pick_success'));
      this.apiService.refreshTransfer(true)

     }
   )
  }

  updateFactures(){
    if(!this.isoperateur){   let idfrom = this.searchAgenceById(this.initialAgences,this.factureControlls.get('selected_agence_from').value)
      if(idfrom){
        this.factureControlls.get('id_agence_from').setValue(idfrom)
        console.log("AGENCE FROM ",idfrom)

      }else{
        this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
        this.factureControlls.get('selected_agence_from').reset()
        return 1
      }}


    console.log("Update facture body Navbar  ",this.factureControlls.value)
    if(!this.transfertService.transferControlFraisByAmount(parseFloat(this.factureControlls.get('fee').value),parseFloat(this.factureControlls.get('amount').value))){
      this.notifyService.infoNotify('top','center',this.translate.instant('Message.enter_valide_frais'));
      return 1 ;
    }
      this.factureControlls.disable()
    this.apiService.updateFacture(this.factureControlls.value,this.factureControlls.get('id').value).subscribe(
      res=>{
        console.log("IN UPDATE FACTURE API")
        console.log("update facture ",res)
      },
      (error)=>{
        console.log("Update facture  error ",error)
        //this.notifyService.errorNotify('top','center',"Modification Facture a echouer");
        this.notifyService.servorErrorNotify(error.status,error)
        this.factureControlls.enable()
      },
      ()=>{
        this.factureControlls.reset()
        this.factureControlls.enable()
        this.modal.dismissAll("close")
        this.apiService.refreshTransfer(true)
        this.notifyService.successNotify('top','center',this.translate.instant('Message.facture_update_success'));
      }
    )
  }

  openfacture(x,transfert){

    console.log("Facture ",transfert)
    this.curent_facture_type = ''
    //return 1
    this.factureControlls.get('id').setValue(transfert.id);
    this.apiService.getTransferDetails(transfert.id).subscribe(res=>{
      this.current_transfer = res.transfer;
      transfert = this.current_transfer
      console.log("Details Transfert",this.current_transfer);
    },error =>{ console.log(error)},
    ()=>{
      this.modal.dismissAll('modal')

    this.factureControlls.get('id').setValue(transfert.id);
    this.factureControlls.get('amount').setValue(transfert.amount);
    this.factureControlls.get("phone_sender").setValue(transfert.phone_sender)
    this.factureControlls.get("fee").setValue(transfert.fee)
    this.factureControlls.get("id_agence_from").setValue(transfert.id_agence_from)
    this.factureControlls.get("reference_facture").setValue(transfert.reference_facture)
    //this.factureControlls.get("type").setValue(transfert.type)
    this.factureControlls.get("id_agence_to").setValue(transfert.id_agence_to)
    this.factureControlls.get("type_transfert").setValue(transfert.type_transfert)


    let agenceTo= this.initialAgences.filter(item=>item.id == transfert.id_agence_to )
  //  this.factureControlls.get("selected_agence_to").setValue(agenceTo[0].name+"("+this.searchAgenceByCode(this.initAgences,transfert.id_agence_to)+")")
  if(agenceTo.length >0){
    this.curent_facture_type = agenceTo[0].type
    this.factureControlls.get('type').setValue(this.curent_facture_type)
  }else{
    this.curent_facture_type = ''
  }

   let agencefrom= this.initialAgences.filter(item=>item.id == transfert.id_agence_from )
   console.log("Agence s facture ",this.initialAgences, "agence from ",agencefrom)
  //  this.factureControlls.get("type").setValue(agenceTo[0].type)

  this.factureControlls.get("selected_agence_from").setValue(agencefrom[0].name+"("+agencefrom[0].code_agence+")")
  console.log("facture body ", this.factureControlls.value)



    this.modal.open(x,{ariaLabelledBy : 'modal-basic-title', size: 'l',backdrop:'static',keyboard:false})
    .result.then( (result:any)=>{
        this.fermer = `closed with :${result}`
    },(reason)=>{
      this.libereControlls.reset()
      this.fermer = `Dismissed ${this.dism(reason)}`;
    }
    );
    })

  }

  findAgenceManagerId(id_agence){
    let agence=[];
    agence = this.initialAgences.filter(item=>item.id == id_agence)
    //console.log(" finder agency ",agence)
    if(agence.length >0){
      return agence[0].id_manager
    }
    return -1
  }


  checkHaveToLibereTransfert(transfer){
    if(!this.checkHaveToDoActionInTransfert(transfer)){return false}
    if(this.manager_info.level > 1  && (this.current_manager_agencies[0].id != transfer.id_agence_to    && !this.agenceInConnectedZone(transfer.id_agence_to))){
      return false;
    }
    if((this.findAgenceManagerId(transfer.id_agence_to) != this.managerId && !this.current_manager_agencies.find(item=>item.id==transfer.id_agence_to) &&  !this.agenceInConnectedZone(transfer.id_agence_to))){
      return false
    }
    if(transfer.level_depart<2){
      if(this.manager_info.level == 1  && this.current_manager_agencies.find(item=>item.id == transfer.id_agence_to)){
        return true;
      }
      if((this.findAgenceManagerId(transfer.id_agence_from) == this.managerId && this.current_manager_agencies.length == 1)){
        return false
      }
      if((this.findAgenceManagerId(transfer.id_agence_to) == this.managerId && this.current_manager_agencies.length > 1)){
        return true
      }
      return true
    }
    else{
      console.log("Liberation autorisation ",transfer)
      if(this.current_manager_agencies.find(item=>item.id == transfer.id_agence_to)){
        return true
      }

      if(this.findAgenceManagerId(transfer.id_agence_to)==this.managerId){
        return true;
      }
    }

    /*if((this.findAgenceManagerId(transfer.id_agence_from) == this.managerId && this.agencesFrom.length == 1)){
      return false
    }
    if((this.findAgenceManagerId(transfer.id_agence_from) == this.managerId && this.agencesFrom.length > 1)){
      if(transfer.level_depart > 1 && transfer.type_transfert =='normal' && (this.findAgenceManagerId(transfer.id_agence_from) == this.managerId ) ){
        if(this.findAgenceManagerId(transfer.id_agence_from) == this.findAgenceManagerId(transfer.id_agence_to)){
          return true
        }
        return false
      }
      return true
    }
    return true
    */
  }

  checkHaveToConfirmDepot(transfer){
    if(this.manager_info.level > 1 && transfer.type_transfert=='cash_depot' && !this.agenceInConnectedZone(transfer.id_agence_to)){
      return false;
    }

    if((this.findAgenceManagerId(transfer.id_agence_to) == this.managerId && this.current_manager_agencies.length > 1)){
      return true
    }
    if(this.current_manager_agencies.find(item=>item.id==transfer.id_agence_to)){
      return true;
    }
    if(this.initialAgences.find(item=>(item.id_manager==this.managerId && item.type=='api'))){
      return true;
    }

  }


  changeDepotType(type){
    this.depot_type = type;
  }

  IncreaseCapitalOrAgence(){
    let type = this.capitalControls.get('type').value
    let agence =[]
    let idAgenceTo = this.searchAgenceById(this.initialAgences,this.capitalControls.get('selected_agence_from').value)
    if(idAgenceTo){
      this.capitalControls.get('id_agence_from').setValue(idAgenceTo)
      agence= this.initAgences.filter(item=>item.id == idAgenceTo)
      this.capitalControls.patchValue({
        number_account:agence[0].number_account,
        id_manager:agence[0].id_manager
      })
    }
    if(!idAgenceTo){
      this.capitalControls.get('selected_agence_from').reset()
      this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
      return 1
    }

    if(type == 'increase_caisse'){
      console.log("Type capital action ",type, "Body :",this.capitalControls.value)
      this.capitalControls.disable()
      this.apiService.increaseCapitalCaisseAccount(this.capitalControls.value).subscribe(
        res=>{
          console.log(res)
        },
        (error)=>{
          //this.notifyService.errorNotify('top','center',"L'alimentation de la caisse a echouer");
          this.notifyService.servorErrorNotify(error.status,error)
          this.capitalControls.enable()
        },
        ()=>{
          this.capitalControls.reset()
          this.capitalControls.enable()
          this.notifyService.successNotify('top','center',this.translate.instant('Message.caisse_increased'));
          this.modal.dismissAll('close');
        }
      )
    }
    if(type == 'increase_capital'){
      console.log("Type capital action ",type, "Body :",this.capitalControls.value)
      this.capitalControls.disable()
      this.apiService.increaseCapitalAccount(this.capitalControls.value).subscribe(
        res=>{
          console.log(res)
        },
        (error)=>{
          this.notifyService.servorErrorNotify(error.status,error)
          this.capitalControls.enable()
          console.log(error)
          //this.notifyService.errorNotify('top','center',"L'alimentation du capital a echouer");
        },
        ()=>{
          this.capitalControls.reset()
          this.capitalControls.enable()
          this.notifyService.successNotify('top','center',this.translate.instant('Message.cp_increased'));
          this.modal.dismissAll('close');
        }
      )
    }

  }

  addBankVersement(){

    this.banKControls.disable()
    this.bankPasswordControls.disable()
    this.banKControls.patchValue({

      id_agence_to:'22',
      nom_agence:'EL IMARA',
      code_agence:"9000",
      password:''+this.bankPasswordControls.get('password').value,
      //beneficiaire:"37486414"

    })

    if(this.isoperateur){
      this.banKControls.patchValue({
        id_agence_from:''+this.agence.id+'',
        id_agence_to:'22',
        nom_agence:'EL IMARA',
        code_agence:"9000",
        password:''+this.bankPasswordControls.get('password').value,
        //beneficiaire:"37486414"

      })

      console.log("versement Formulaire ",this.banKControls.value)



    }else if((this.ismanager || this.issupervisor)){
      if(this.current_manager_agencies.length == 1){
        this.banKControls.patchValue({
          id_agence_form:''+this.current_manager_agencies[0].id+''
        })
      }else{
        let idfrom = this.searchAgenceById(this.current_manager_agencies,this.banKControls.get('selected_agence_from').value)
        if(idfrom){
          this.banKControls.get('id_agence_from').setValue(idfrom)
        }else{

            this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
            this.banKControls.get('selected_agence_from').reset()
            return 1

        }
      }
    }else{
      let idfrom = this.searchAgenceById(this.agences,this.banKControls.get('selected_agence_from').value)
        if(idfrom){
          this.banKControls.get('id_agence_from').setValue(''+idfrom+'')
        }else{

            this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
            this.banKControls.get('selected_agence_from').reset()
            return 1

        }
    }
    console.log("bankControls value ",this.banKControls.value)

    this.apiService.bimBankVersement(this.banKControls.value).subscribe(
      res=>{
        console.log("le resultat bimbank",res)
      },
      (error)=>{
        console.log('error bim bank', error)
        this.bankPasswordControls.enable()
        this.notifyService.servorErrorNotify(error.status,error)
        if(error.error.code!='bankoperation_confirm_password_error'){
          this.bim_valid_phone = false

        }
        this.bankPasswordControls.reset()

        this.banKControls.enable();
      },
      ()=>{
        console.log("Versement success"),
        this.notifyService.successNotify('top','center',this.translate.instant('Message.versement_success'))
        this.modal.dismissAll("close")
        this.banKControls.reset()
        this.bankPasswordControls.reset()
        this.banKControls.enable()
        this.bankPasswordControls.enable()
        this.bim_valid_phone=false;
        this.bank_operation_type=''
        this.apiService.refreshTransfer(true)
        this.banKControls.controls['montant'].setValidators([])
        this.banKControls.controls['selected_agence_from'].setValidators([])
        this.banKControls.controls['code'].setValidators([])
      }
    )
  }
  addMauripayVersement(){

    //this.mauripayControls.disable()
    this.mauripayPasswordControls.disable()
    this.mauripayControls.patchValue({

      // id_agence_to:'22',
      // nom_agence:'EL IMARA',
      // code_agence:"9000",
      password:''+this.mauripayPasswordControls.get('password').value,
      //beneficiaire:"37486414"

    })

    if(this.isoperateur){
      this.mauripayControls.patchValue({
        id_agence_from:''+this.agence.id+'',
        // id_agence_to:'22',
        // nom_agence:'EL IMARA',
        // code_agence:"9000",
        password:''+this.mauripayPasswordControls.get('password').value,
        //beneficiaire:"37486414"

      })

      console.log("versement Formulaire ",this.mauripayControls.value)



    }else if((this.ismanager || this.issupervisor)){
      if(this.current_manager_agencies.length == 1){
        this.mauripayControls.patchValue({
          id_agence_form:''+this.current_manager_agencies[0].id+''
        })
      }else{
        let idfrom = this.searchAgenceById(this.current_manager_agencies,this.mauripayControls.get('selected_agence_from').value)
        if(idfrom){
          this.mauripayControls.get('id_agence_from').setValue(idfrom)
        }else{

            this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
            this.mauripayControls.get('selected_agence_from').reset()
            return 1

        }
      }
    }else{
      let idfrom = this.searchAgenceById(this.agences,this.mauripayControls.get('selected_agence_from').value)
        if(idfrom){
          this.mauripayControls.get('id_agence_from').setValue(''+idfrom+'')
        }else{

            this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
            this.mauripayControls.get('selected_agence_from').reset()
            return 1

        }
    }
    console.log("mauripayControls value ",this.mauripayControls.value)

    this.apiService.mauripayVersement(this.mauripayControls.value).subscribe(
      res=>{
        console.log("le resultat mauripay",res)
      },
      (error)=>{
        console.log('error mauripay mauripay', error)
        this.mauripayPasswordControls.enable()
        
        if(error.error.code!='"bankoperation_confirm_password_error"'){
          this.notifyService.errorNotify('top','center',this.translate.instant('Message.password_invalid'));
        }else{
          this.notifyService.errorNotify('top','center',this.translate.instant('Message.servor_error'));
        }
         this.mauripayPasswordControls.reset()

        // this.mauripayControls.enable();
      },
      ()=>{
        console.log("Versement success"),
        this.notifyService.successNotify('top','center',this.translate.instant('Message.versement_success'))
        this.modal.dismissAll("close")
        this.mauripayControls.reset()
        this.mauripayPasswordControls.reset()
        this.mauripayControls.enable()
        this.mauripayPasswordControls.enable()
        this.mauripay_valid_phone=false;
        this.mauripay_operation_type=''
        this.apiService.refreshTransfer(true)
        this.mauripayControls.controls['montant'].setValidators([])
        this.mauripayControls.controls['selected_agence_from'].setValidators([])
        this.mauripayControls.controls['code'].setValidators([])
      }
    )
  }

  getBimBankAccountByPhone(action){
    this.bank_operation_type=action

    this.banKControls.disable()
    if(this.bank_operation_type=='bank_depot'){
      this.apiService.getBimBankAccountByPhone(this.banKControls.get('phone_beneficaire').value).subscribe(
        (res:any)=>{
          console.log("Bim verif result ",res)

          this.bim_bank_current_account.last_name = res.body.last_name
          this.bim_bank_current_account.first_name = res.body.first_name
          this.bim_bank_current_account.email = res.body.email
          this.bim_bank_current_account.adresse = res.body.adresse
        },
        (error)=>{
          this.bim_valid_phone = false
          this.notifyService.errorNotify('top','center',this.translate.instant('حساب المستفيد غير موجود أو غير مفعل. رجاء التأكد')) 
          //this.notifyService.servorErrorNotify(error.status,error)
          this.banKControls.enable()
          console.log("bim verification ",error)
        },
        ()=>{
          this.bim_valid_phone = true;
          this.banKControls.enable()
          // set amount required after validate the number of client
          this.banKControls.controls['montant'].setValidators([Validators.max(300000),Validators.pattern(/^[.\d]+$/),Validators.required])
          this.banKControls.controls['selected_agence_from'].setValidators([Validators.required])

          console.log("bank form ",this.banKControls.value)
          console.log("bank verif form ",this.veriBankAccountControls.value)
        }
      )
    }
    if(this.bank_operation_type == 'bank_retrait'){
      this.getBimBankListRetraitOfClient();
    }
  }

  getMauripayAccountByPhone(action){
    this.mauripay_operation_type=action

    this.mauripayControls.disable()
    if(this.mauripay_operation_type=='mauripay_depot'){
      this.apiService.getMauripayAccountByPhone(this.mauripayControls.get('phone_beneficaire').value).subscribe(
        (res:any)=>{
          console.log("mauripay verif result ",res)

          this.mauripay_current_account.last_name = res.body.prenom
          this.mauripay_current_account.first_name = res.body.nom
          // this.mauripay_current_account.email = res.body.email
          // this.mauripay_current_account.adresse = res.body.adresse
        },
        (error)=>{
          this.mauripay_valid_phone = false

          this.notifyService.servorErrorNotify(error.status,error)
          this.mauripayControls.enable()
          console.log("mauripay verification ",error)
        },
        ()=>{
          this.mauripay_valid_phone = true;
          this.mauripayControls.enable()
          // set amount required after validate the number of client
          this.mauripayControls.controls['montant'].setValidators([Validators.max(300000),Validators.pattern(/^[.\d]+$/),Validators.required])
          this.mauripayControls.controls['selected_agence_from'].setValidators([Validators.required])

          console.log("mauripay form ",this.mauripayControls.value)
          //console.log("mauripay verif form ",this.veriBankAccountControls.value)
        }
      )
    }
    if(this.mauripay_operation_type == 'mauripay_retrait'){
      this.getMauripayListRetraitOfClient();
    }
  }
  annulerVersement(){
    this.modal.dismissAll("close")
          this.banKControls.reset()
          this.banKControls.enable()
          this.bim_valid_phone=false;
          this.bank_operation_type=''
          this.banKControls.controls['montant'].setValidators([])
          this.banKControls.controls['selected_agence_from'].setValidators([])
          this.banKControls.controls['code'].setValidators([])
        console.log("bimvalidphone state after retrait anuled ",this.bim_valid_phone)
  }
  annulerVersementMauripay(){
    this.modal.dismissAll("close")
          this.mauripayControls.reset()
          this.mauripayControls.enable()
          this.mauripay_valid_phone=false;
          this.mauripay_operation_type=''
          this.mauripayControls.controls['montant'].setValidators([])
          this.mauripayControls.controls['selected_agence_from'].setValidators([])
          this.mauripayControls.controls['code'].setValidators([])
        console.log("mauripayvalidphone state after retrait anuled ",this.mauripay_valid_phone)
  }
  annulerRetraitCadorim(){
    this.modal.dismissAll("close")
          this.cadorimControls.reset()
          this.cadorimControls.enable()
          this.cadorim_valid_phone=false;
          this.cadorim_valid_commande=false;
          this.cadorimControls.controls['selected_agence_from'].setValidators([])
          this.cadorimControls.controls['code'].setValidators([])
        console.log("cadorimvalidphone state after retrait anuled ",this.cadorim_valid_phone)
  }

  inputControlCommand(input:  any,type: string){
    console.log(input.target.value);
    if(input.target.value.length >= 15 ){
      input.target.value = input.target.value.slice(0,15)
     }
    if( type=='commande'){
      this.cadorimControls.patchValue({
        idcommande:input.target.value,
        //phone_beneficaire:input.target.value.slice(0,0)
      });
      return;
    }

  }

  inputControlPhone(input:  any,type: string){
    console.log(input.target.value);
    if(input.target.value.length >= 8 ){
      input.target.value = input.target.value.slice(0,8)
     }
    if(type=='sender'){
      this.transfertControlls.patchValue({
        phone_sender:input.target.value
      });
      return;
    }
    if( type=='benif'){
      this.cadorimControls.patchValue({
        //idcommande:input.target.value.slice(0,0),
        phone_beneficaire:input.target.value
      });
      this.transfertControlls.patchValue({
        phone_beneficaire:input.target.value
      });
      this.banKControls.patchValue({
        phone_beneficaire:input.target.value
      });
      this.mauripayControls.patchValue({
        phone_beneficaire:input.target.value
      });
      return;
    }
    if( type=='depot_benef'){
      this.depoControlls.patchValue({
        phone_beneficaire:input.target.value
      });
      return;
    }


  }

  controlFrais(frais: any){
    var frai =(<HTMLInputElement>document.getElementById('fee'));
    if(frais.target.value <=300){
      frai.value = String(0);
      this.transfertControlls.get("fee").setValue(frai.value);
      //frai.setAttribute("disabled","")
    }else{
      frai.value=null;
      this.transfertControlls.get("fee").setValue(null);
      //frai.removeAttribute("disabled");
    }

    if(frais.value == 0){
        frai.value = null;
        //frai.removeAttribute("disabled");
    }
  }

  getAgenceNameByManagerPosition(id_agence){

    if(this.ismanager || this.issupervisor){
      return this.transfertService.getAgenceNameByManagerPosition(this.initialAgences,id_agence,this.manager_info,this.managerAgencyChild)
    }else{
      let agence= this.initialAgences.filter(item=>item.id == id_agence)
      if(agence && agence.length >0){
        return agence[0].name
      }
      return "introuvable"
    }

  }

  getAgenceManagerChilds(){
    this.apiService.getAgenceManagerChilds(this.managerId).subscribe(
      res=>{
        this.managerAgencyChild = res.agency_manager_childs;
      },
      (error)=>{console.log(error)},
      ()=>{
        console.log(this.managerAgencyChild)
      }
    )
  }


  checkHaveAutorisationInTransfert(id){
    if(this.isadmin || this.is_sous_admin){
      return true
    }else{
      if(this.manager_info.level > 1 &&  !this.agenceInConnectedZone(id)){
        return false;
      }
      let agence=[]
     agence = this.current_manager_agencies.filter(item => item.id == id);
    if(agence.length>0){
      return 1
    }
    return false;
    }
  }

  confirmDepot(transfert){

    let confirm_depot_body={
      "id":transfert.id,
      "id_agence_from":transfert.id_agence_from,
      "id_agence_to":transfert.id_agence_to
    }
    //console.log(id);
   // console.log(this.ag_from);
   // console.log(this.confimdepotForm.value)
   // this.confimdepotForm.disable()
    this.depot_confirm_active=false
    this.apiService.confirmDepot(confirm_depot_body).subscribe(
     res=>{
       console.log(res);
     },(error)=>{
      this.depot_confirm_active=true
       console.log(error)
       //this.modal.dismissAll("close")
       //this.confimdepotForm.reset()
       this.notifyService.servorErrorNotify(error.status,error)
      // this.confimdepotForm.enable()
       //this.notifyService.errorNotify('top','center',"La confirmation a echouer");
      },
     ()=>{

       if(this.ismanager || this.issupervisor){
        // this.getManagerDepotsCash(this.manager_info.id);
       }

       this.depot_confirm_active=true

       this.modal.dismissAll("close")
       //this.confimdepotForm.enable()
      this.notifyService.successNotify('top','center',this.translate.instant('Message.transfert_pick_success'));
      this.apiService.refreshTransfer(true)

     }
   )
  }

  UpdateDepotCash(){


    if(!this.isoperateur){

        let idfrom = this.searchAgenceById(this.initialAgences,this.depoControlls.get('selected_agence_from').value)
        if(idfrom){
          this.depoControlls.get('id_agence_from').setValue(idfrom)
          console.log()
          this.ag_from = this.depoControlls.get('id_agence_from').value
        }

        if(!idfrom){
          this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
          this.depoControlls.get('selected_agence_from').reset()
          return 1
        }

    }
    let idAgenceTo = this.searchAgenceById(this.initialAgences,this.depoControlls.get('selected_agence_to').value)
        if(idAgenceTo){
          this.depoControlls.get('id_agence_to').setValue(idAgenceTo)
          console.log(this.depoControlls.value)

        }
        if(!idAgenceTo){
          this.transfertControlls.get('selected_agence_to').reset()
          this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
          return 1
        }
    this.depoControlls.patchValue({
      type_transfert:"cash_depot",
      fee:0,
      id_agence_from:this.ag_from
    })
    if(this.depoControlls.get('id_agence_from').value == this.depoControlls.get('id_agence_to').value ){
      this.notifyService.warningNotify('top','center',"Impossible de faire ce depot");
      return 1
    }
    console.log(this.depoControlls.value)
    let message=""
    this.depoControlls.disable()
    this.apiService.updateTransfert(this.depoControlls.value,this.depoControlls.get('id').value).subscribe(
      res=>{
        console.log(res);
      },(error)=>{
        this.depoControlls.enable()
        message = error.error.message

        //this.depoControlls.reset()
        this.notifyService.servorErrorNotify(error.status,error)
        console.log(error)
      },()=>{
       this.depoControlls.reset()
       this.depoControlls.enable()
       //this.getAgencies();
       if(!this.isoperateur){
         //this.getAgencies()
       }
       //this.AllAgencies();
       //this.getTypes();
       this.notifyService.successNotify('top','center',this.translate.instant('Message.depot_success'));
       this.modal.dismissAll('close');
      });
  }

  checkHaveToDoActionInTransfert(transfert){
    return this.transfertService.checkIfHaveToDoActionsByAgenciesStatus(transfert,this.initialAgences)
  }

  libereDemandeBankRetrait(demande){
    alert("demande :"+demande.title)
    demande.istraited = 1;


  }

  getBimBankListRetraitOfClient(){
    console.log("welcome to the list of bim retrait ",this.banKControls.value)
    this.apiService.bimBankClientListeRetraits(this.banKControls.value).subscribe(
      res=>{
        console.log('bim list retrait retour ',res)
        this.demandes_listes = res.retrait_demandes
      },
      (error)=>{
        //this.bim_valid_phone = false
        //this.bim_valid_phone = true // breche pour test

        this.notifyService.infoNotify('top','center',this.translate.instant('Message.retrait_demande_not_found'))
        this.banKControls.enable()
        console.log("bim list retrait error ",error);
      },
      ()=>{
        this.bim_valid_phone = true;
        this.banKControls.enable()
        // set amount required after validate the number of client
        //this.banKControls.controls['montant'].setValidators([Validators.pattern(/^[.\d]+$/),Validators.required])
        if(this.bank_operation_type == 'bank_retrait'){

          this.banKControls.controls['code'].setValidators([Validators.minLength(4),Validators.required,Validators.pattern(/^[.\d]+$/)])
          this.banKControls.controls['selected_agence_f'].setValidators([Validators.required])
          console.log("bank form  retrait get list",)

        }
        console.log("bank form ",this.banKControls.value)
      }

    )
  }
  getCadorimListRetrait(){
    console.log("get cadorim retrait ",this.cadorimControls.value)
    const idcommande = this.cadorimControls.get('idcommande').value;
    const phone_beneficaire = this.cadorimControls.get('phone_beneficaire').value;
    //if (idcommande && idcommande.length >=8){
      console.log("idcommande and phone: ",idcommande +" , " +phone_beneficaire)
      this.apiService.cadorimListeRetraits(this.cadorimControls.value).subscribe(
        res=>{
          console.log('cadorim list retrait retour ',res)
          this.demandes_listes = res.retrait_demandes
        },
        (error)=>{
  
          this.notifyService.infoNotify('top','center',this.translate.instant('Message.retrait_demande_not_found'))
          this.cadorimControls.enable()
          console.log("cadorim list retrait error ",error);
        },
        ()=>{
          this.cadorim_valid_phone = true;
          this.cadorim_valid_commande = true;
          this.cadorimControls.enable()
          console.log("cadorim form ",this.cadorimControls.value)
        }
  
      )
    // }else{
    //   console.log("phone_beneficaire ",phone_beneficaire)
    //   this.apiService.cadorimListeRetraitsByPhone(this.cadorimControls.value).subscribe(
    //     res=>{
    //       console.log('cadorim list retrait retour ',res)
    //       this.demandes_listes = res.retrait_demandes
    //     },
    //     (error)=>{
  
    //       this.notifyService.infoNotify('top','center',this.translate.instant('Message.retrait_demande_not_found'))
    //       this.cadorimControls.enable()
    //       console.log("cadorim list retrait error ",error);
    //     },
    //     ()=>{
    //       this.cadorim_valid_phone = true;
    //       this.cadorim_valid_commande = true;
    //       this.cadorimControls.enable()
    //       console.log("cadorim form ",this.cadorimControls.value)
    //     }
  
    //   )
    // }
    
  }


  getMauripayListRetraitOfClient(){
    console.log("welcome to the list of mauripay retrait ",this.mauripayControls.value)
    this.apiService.mauripayClientListeRetraits(this.mauripayControls.value).subscribe(
      res=>{
        console.log('mauripay list retrait retour ',res)
        this.demandes_listes = res.retrait_demandes
      },
      (error)=>{

        this.notifyService.infoNotify('top','center',this.translate.instant('Message.retrait_demande_not_found'))
        this.mauripayControls.enable()
        console.log("mauripay list retrait error ",error);
      },
      ()=>{
        this.mauripay_valid_phone = true;
        this.mauripayControls.enable()
        // set amount required after validate the number of client
        //this.mauripayControls.controls['montant'].setValidators([Validators.pattern(/^[.\d]+$/),Validators.required])
        if(this.mauripay_operation_type == 'mauripay_retrait'){

          //this.mauripayControls.controls['code'].setValidators([Validators.minLength(4),Validators.required,Validators.pattern(/^[.\d]+$/)])
          //this.mauripayControls.controls['selected_agence_f'].setValidators([Validators.required])
          console.log("mauripay form  retrait get list",)

        }
        console.log("mauripay form ",this.mauripayControls.value)
      }

    )
  }


  // Bim bank retrait confirmation
  BimBankValidationRetraitForClient(demande){
    this.banKControls.patchValue({

      id_agence_from:'22',
      nom_agence:'EL IMARA',
      code_agence:"9000",
      montant:parseFloat(demande.montant),
      id_retrait:demande.retrait_id,
      password:''+this.bankPasswordControls.get('password').value
      //beneficiaire:"37486414"

    })
    if(this.isoperateur){
      this.banKControls.patchValue({
        id_agence_to:''+this.agence.id+'',
        //beneficiaire:"37486414"

      })
    }else if((this.ismanager || this.issupervisor)){
      if(this.current_manager_agencies.length == 1){
        this.banKControls.patchValue({
          id_agence_to:''+this.current_manager_agencies[0].id+''
        })
      }else{
        let idAgTo = this.searchAgenceById(this.current_manager_agencies,this.banKControls.get('selected_agence_from').value)
        if(idAgTo){
          this.banKControls.get('id_agence_to').setValue(idAgTo)
        }else{

            this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
            this.banKControls.get('selected_agence_from').reset()
            return 1

        }
      }
    }else{
      let idAgTo = this.searchAgenceById(this.agences,this.banKControls.get('selected_agence_from').value)
        if(idAgTo){
          this.banKControls.get('id_agence_to').setValue(''+idAgTo+'')
        }else{

            this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
            this.banKControls.get('selected_agence_from').reset()
            return 1

        }
    }

    console.log("retrait bim value ",this.banKControls.value)
    //return 1;
    this.banKControls.disable()
    this.bankPasswordControls.disable()
    this.apiService.BimBankValidationRetraitForClient(this.banKControls.value).subscribe(
      res=>{
        console.log("retour of validation retrait ",res)
      },
      (error)=>{

        console.log("error confirm retrait ",error)
        this.notifyService.servorErrorNotify(error.status,error)
        //this.bim_valid_phone = false
        if(error.error.code!='bankoperation_confirm_password_error'){
          //this.bim_valid_phone = false

        }
        this.bankPasswordControls.reset();
        this.banKControls.enable();
        this.bankPasswordControls.enable();
      },
      ()=>{
        console.log("Versement success"),
        this.notifyService.successNotify('top','center',this.translate.instant('Message.versement_success'))
        this.modal.dismissAll("close")
        this.banKControls.reset()
        this.banKControls.enable()
        this.bankPasswordControls.reset()
        this.bankPasswordControls.enable()
        this.bim_valid_phone=false;
        this.bank_operation_type=''
        this.apiService.refreshTransfer(true)
        this.banKControls.controls['montant'].setValidators([])
        this.banKControls.controls['selected_agence_from'].setValidators([])
        this.banKControls.controls['code'].setValidators([])
      }

    )


  }

  mauripayValidationRetraitForClient(demande){
    this.mauripayControls.patchValue({

      type:demande.tel_bf?'extra':"normal",
      montant:parseFloat(demande.montant),
      id_retrait:demande.id,
      password:''+this.mauripayPasswordControls.get('password').value,
      last_name_benef:this.mauripayPasswordControls.get('last_name_beneficiare').value,
      nni_benef:this.mauripayPasswordControls.get('nni_beneficiare').value
    })

   
    if(demande.tel_bf != null && parseFloat(demande.montant) >= this.transfertService.MONTANT_NEEDED_ALL_INFOS &&
      ((this.mauripayPasswordControls.get("nni_beneficiare").value == "" || this.mauripayPasswordControls.get("nni_beneficiare").value == undefined)||

      (this.mauripayPasswordControls.get("last_name_beneficiare").value == "" || this.mauripayPasswordControls.get("last_name_beneficiare").value == undefined)
      )){
        this.notifyService.infoNotify('top','center',this.translate.instant('Message.enter_benef_infos'));
        return 1
      }

    if(this.isoperateur){
      this.mauripayControls.patchValue({
        id_agence_to:''+this.agence.id+'',
        //beneficiaire:"37486414"

      })
    }else if((this.ismanager || this.issupervisor)){
      if(this.current_manager_agencies.length == 1){
        this.mauripayControls.patchValue({
          id_agence_to:''+this.current_manager_agencies[0].id+''
        })
      }else{
        let idAgTo = this.searchAgenceById(this.current_manager_agencies,this.mauripayControls.get('selected_agence_from').value)
        if(idAgTo){
          this.mauripayControls.get('id_agence_to').setValue(idAgTo)
        }else{

            this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
            this.mauripayControls.get('selected_agence_from').reset()
            return 1

        }
      }
    }else{
      let idAgTo = this.searchAgenceById(this.agences,this.mauripayControls.get('selected_agence_from').value)
        if(idAgTo){
          this.mauripayControls.get('id_agence_to').setValue(''+idAgTo+'')
        }else{

            this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
            this.mauripayControls.get('selected_agence_from').reset()
            return 1

        }
    }

    console.log("retrait mauripay value ",this.mauripayControls.value)
    //return 1;
    //this.mauripayControls.disable()
    this.mauripayPasswordControls.disable()
    this.apiService.mauripayValidationRetraitForClient(this.mauripayControls.value).subscribe(
      res=>{
        console.log("retour of validation retrait ",res)
      },
      (error)=>{

        console.log("error confirm retrait ",error)
        if(error.error.code!='"bankoperation_confirm_password_error"'){
          this.notifyService.errorNotify('top','center',this.translate.instant('Message.password_invalid'));
        }else{
          this.notifyService.errorNotify('top','center',this.translate.instant('Message.servor_error'));
        }
        this.mauripayPasswordControls.reset();
        //this.mauripayControls.enable();
        this.mauripayPasswordControls.enable();
      },
      ()=>{
        console.log("Versement success"),
        this.notifyService.successNotify('top','center',this.translate.instant('Message.versement_success'))
        this.modal.dismissAll("close")
        this.mauripayControls.reset()
        this.mauripayControls.enable()
        this.mauripayPasswordControls.reset()
        this.mauripayPasswordControls.enable()
        this.mauripay_valid_phone=false;
        this.mauripay_operation_type=''
        this.apiService.refreshTransfer(true)
        this.mauripayControls.controls['montant'].setValidators([])
        this.mauripayControls.controls['selected_agence_from'].setValidators([])
        this.mauripayControls.controls['code'].setValidators([])
      }

    )


  }
  cadorimValidateRetrait(demande){
    this.cadorimControls.patchValue({
      montant:parseFloat(demande.paiements.somme_mru),
      fee:parseFloat(demande.fee),
      phone_benef:demande.phone_benef,
      id_retrait:demande.id_commande,
      password:''+this.cadorimPasswordControls.get('password').value,
      last_name_benef:this.cadorimPasswordControls.get('last_name_beneficiare').value ? this.cadorimPasswordControls.get('last_name_beneficiare').value : demande.nom_benef,
      nni_benef:this.cadorimPasswordControls.get('nni_beneficiare').value
    })

   
    if(demande.tel_bf != null && parseFloat(demande.montant) >= this.transfertService.MONTANT_NEEDED_ALL_INFOS &&
      ((this.cadorimPasswordControls.get("nni_beneficiare").value == "" || this.cadorimPasswordControls.get("nni_beneficiare").value == undefined)||

      (this.cadorimPasswordControls.get("last_name_beneficiare").value == "" || this.cadorimPasswordControls.get("last_name_beneficiare").value == undefined)
      )){
        this.notifyService.infoNotify('top','center',this.translate.instant('Message.enter_benef_infos'));
        return 1
      }

    if(this.isoperateur){
      this.cadorimControls.patchValue({
        id_agence_to:''+this.agence.id+'',
        //beneficiaire:"37486414"

      })
    }else if((this.ismanager || this.issupervisor)){
      if(this.current_manager_agencies.length == 1){
        this.cadorimControls.patchValue({
          id_agence_to:''+this.current_manager_agencies[0].id+''
        })
      }else{
        let idAgTo = this.searchAgenceById(this.current_manager_agencies,this.cadorimControls.get('selected_agence_from').value)
        if(idAgTo){
          this.cadorimControls.get('id_agence_to').setValue(idAgTo)
        }else{

            this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
            this.cadorimControls.get('selected_agence_from').reset()
            return 1

        }
      }
    }else{
      let idAgTo = this.searchAgenceById(this.agences,this.cadorimControls.get('selected_agence_from').value)
        if(idAgTo){
          this.cadorimControls.get('id_agence_to').setValue(''+idAgTo+'')
        }else{

            this.notifyService.infoNotify('top','center',this.translate.instant('Message.select_valid_agence_from'))
            this.cadorimControls.get('selected_agence_from').reset()
            return 1

        }
    }

    console.log("retrait cadorim value ",this.cadorimControls.value)
    //return 1;
    //this.cadorimControls.disable()
    this.cadorimPasswordControls.disable()
    this.apiService.cadorimValidateRetrait(this.cadorimControls.value).subscribe(
      res=>{
        console.log("retour of validation retrait ",res)
      },
      (error)=>{

        console.log("error confirm retrait ",error)
        if(error.error.code!='"bankoperation_confirm_password_error"'){
          this.notifyService.errorNotify('top','center',this.translate.instant('Message.password_invalid'));
        }else{
          this.notifyService.errorNotify('top','center',this.translate.instant('Message.servor_error'));
        }
        this.cadorimPasswordControls.reset();
        //this.cadorimControls.enable();
        this.cadorimPasswordControls.enable();
      },
      ()=>{
        console.log("Retrait success"),
        this.notifyService.successNotify('top','center',this.translate.instant('Message.versement_success'))
        this.modal.dismissAll("close")
        this.cadorimControls.reset()
        this.cadorimControls.enable()
        this.cadorimPasswordControls.reset()
        this.cadorimPasswordControls.enable()
        this.cadorim_valid_phone=false;
        this.apiService.refreshTransfer(true)
        this.cadorimControls.controls['montant'].setValidators([])
        this.cadorimControls.controls['selected_agence_from'].setValidators([])
        this.cadorimControls.controls['code'].setValidators([])
      }

    )


  }

  sendTransfertnoWhitespaceValidator(control: FormControl) {
    if((control.value as string).length==0){
      return null;
    }
    if((control.value as string).indexOf(' ') >= 0){
      return {'whitespace': true}
  }
    const isWhitespace = (control && control.value && control.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  resetBankOperationModal(){
    this.banKControls.reset();
    this.bim_valid_phone = false;
    this.bank_operation_type='';
    this.banKControls.controls['montant'].setValidators([])
    this.banKControls.controls['selected_agence_from'].setValidators([])
    this.banKControls.controls['code'].setValidators([])
  }

  setBankOperationOrigine(origine){
    this.bankOperationOrigine = origine;
  }

  resetMauripayOperationModal(){
    this.mauripayControls.reset();
    this.mauripay_valid_phone = false;
    this.mauripay_operation_type='';
    this.mauripayControls.controls['montant'].setValidators([])
    this.mauripayControls.controls['selected_agence_from'].setValidators([])
    this.mauripayControls.controls['code'].setValidators([])
  }
  resetCadorimOperationModal(){
    this.cadorimControls.reset();
    this.cadorim_valid_phone = false;
    this.cadorim_valid_commande = false;
    this.cadorimControls.controls['selected_agence_from'].setValidators([])
    this.cadorimControls.controls['code'].setValidators([])
  }


  
}

